import { generateUniqueId } from "app/functions";
import { useSelector } from "react-redux";
import "./index.scss";

const TextInput = (props) => {
	const {
		label,
		subLabel,
		placeholder,
		type = "text",
		keyboardType = "text",
		name,
		value,
		onChange,
		onKeyUp,
		onInput,
		showError,
		errorMessage,
		img,
		imgAlt,
		disabled = false,
		tabIndex = 0,
		id,
		inputRef,
		isShort,
		autoHeight,
		isOccupation = false,
		maxLength = false,
		className = "",
	} = props;

	const store = useSelector((store) => store);
	const isDesktop = useSelector((store) => store.deviceState.isDesktop);
	const requestingState = useSelector((store) => store.requestingState);
	const loaderState = useSelector((store) => store.loaderState);
	const popupsArray = useSelector((store) => store.popupsArray);
	const occupationAreaFigureData = useSelector((store) => store.occupationAreaData.figure);
	const occupationAreaValidation = useSelector((store) => store.occupationAreaData.validation?.[showError]?.isValid);
	const inputErrorId = `${id ?? name ?? generateUniqueId(12)}_err`;

	// const inputRef = useRef();

	// Usage example
	// <TextInput
	//     label={""}
	//     placeholder={""}
	//     name={""}
	//     value={""}
	//     onChange={handleOnChange}
	//     showError={true}
	//     errorMessage={""}
	// />

	// <TextInput
	//     placeholder={""}
	//     name={""}
	//     value={""}
	//     onChange={handleOnChange}
	//     showError={true}
	//     errorMessage={""}
	//     type={"image"}
	//     img={imageUrl}
	//     imgAlt={imageAlt}
	// />

	const handleOnChange = (e) => {
		if (popupsArray.length > 0 || loaderState) {
			return;
		}

		onChange(e);
	};

	const handlePaste = (e) => {
		// * THE GOAL HERE IS TO HANDLE THE PASTED TEXT AND TRIM IT IN CASE THE TEXT HAS SPACES AT THE BEGINNING/ENDING OF THE STRING
		const pastedText = e.clipboardData.getData("text/plain") || "";
		const trimmedText = pastedText?.trim();

		// * CHECK IF EVENT OBJECT IS VALID AND HAS THE NEEDED PROPERTIES, JUST TO HANDLE AN EDGE CASE WHERE THIS OBJECT IS EMPTY
		if ("target" in (e || {}) && "value" in (e.target || {})) {
			e.preventDefault();
			e.target.value = trimmedText;
			onChange(e);
		}
	};

	return (
		<div
			className={`text-input-wrapper${type === "image" ? " image-input" : ""}${isShort ? " short" : ""} ${className ? className : ""}${
				autoHeight ? " auto-height" : ""
			}`}
		>
			{type === "text" && (
				<>
					{label?.trim() && <span className="text-input-label">{label}</span>}
					{subLabel?.trim() && <span className="text-input-sub-label">{subLabel}</span>}
					<input
						type={keyboardType}
						placeholder={placeholder}
						name={name}
						value={isOccupation ? occupationAreaFigureData[name] ?? "" : value}
						onChange={handleOnChange}
						tabIndex={tabIndex}
						id={id}
						disabled={disabled || requestingState ? true : false}
						onPaste={handlePaste}
						{...(keyboardType === "email" ? { dir: "ltr" } : {})}
						{...(maxLength ? { maxLength: maxLength } : {})}
						{...(showError ? { "aria-describedby": inputErrorId } : {})}
					/>
				</>
			)}

			{type === "image" && (
				<div className="text-input-image-wrapper">
					<div className="text-input-image">
						<img src={img} alt={imgAlt} className={`text-input-image${disabled ? "-disabled" : ""}`} />
					</div>
					<input
						type={keyboardType}
						placeholder={placeholder}
						name={name}
						value={value}
						onChange={handleOnChange}
						disabled={disabled}
						tabIndex={tabIndex}
						id={id}
						onPaste={handlePaste}
						{...(showError ? { "aria-describedby": inputErrorId } : {})}
					/>
				</div>
			)}

			{isOccupation ? (
				occupationAreaValidation?.valid === false ? (
					<label className="text-input-error">{occupationAreaValidation.errMsg}</label>
				) : (
					<label className="text-input-error-placeholder"></label>
				)
			) : (
				showError && (
					<label className="text-input-error" id={inputErrorId}>
						{errorMessage}
					</label>
				)
			)}
		</div>
	);
};
export default TextInput;
