import Api from "api/requests";
import { convertDateToTimestamp, convertTimestampToDate, formatBytes, generateDefaultFormData, isNumber, navigateToNextStep } from "app/functions";
import useApiError from "app/hooks/useApiError";
import useTranslate from "app/hooks/useTranslate";
import useValidate from "app/hooks/useValidate";
import deleteDocumentIcon from "assets/icons/file_delete_icon.svg";
import Button from "components/forms/button";
import DatePicker from "components/forms/date-picker";
import Hint from "components/forms/hint";
import RadioButton from "components/forms/radio-button";
import TextInput from "components/forms/textInput";
import UploadButton from "components/forms/upload-button";
import HeaderTitle from "components/header_title";
import Subheader from "components/subheader";
import { BOOLEAN_VALUES, ID_TYPES } from "constants/input-fields";
import popupTypes from "constants/popup-types";
import { useLayoutEffect, useState } from "react";
import HtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Actions from "redux/actions";
import "./index.scss";

const isTextExists = (text = "") => text?.trim()?.length > 0;
const MAX_VALID_TS = 4102351200000; // Timestamp for 31/12/2099
const DEFAULT_MAX_ALLOWED_SIZE_IN_BYTES = 2097152; // 2MB in Byes. Used in case a value does not exist

const UploadIdManually = ({ fields, location }) => {
	const defaultFormData = generateDefaultFormData(fields);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const translate = useTranslate();
	const signupForm = useSelector((store) => store.signupForm);
	const { max_file_size = DEFAULT_MAX_ALLOWED_SIZE_IN_BYTES, maxAllowedImageSizeInBytes = parseInt(max_file_size) } = useSelector(
		(store) => store.gd.ErrorsLangParam.parameters,
	);
	const headerText = translate("upload_id_hint_header");
	const contentText = translate("upload_id_hint_content");

	const [firstTry, setFirstTry] = useState(true);
	const [formData, setFormData] = useState(defaultFormData);
	const validationObj = { defaultFormData, formData, setFormData, location };
	const validateForm = useValidate(validationObj);
	const handleServerError = useApiError();

	useLayoutEffect(() => {
		const idTypesName = fields.idTypes.name;
		const inputKey = formData[idTypesName]?.inputKey;
		const initialValue = fields.idTypes.options.biometric.value;
		dispatch(Actions.updateSignupForm({ [inputKey]: initialValue }));
	}, []);

	const convertToBase64 = (file, successCallback) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			const base64File = reader.result.split(",")[1];
			successCallback(base64File);
		};
	};

	const handleInputChange = (e) => {
		let type = e?.target?.type;
		let name = e?.target?.name || e?.name;
		let val = e?.target?.value || e?.value;
		let key = formData[name]?.inputKey;

		if (type === "radio") {
			if (key === fields.idTypes.key) {
				dispatch(Actions.updateSignupForm({ [key]: parseInt(val) }));

				// the purpose here is to remove all saved images when id type is changed
				const keysToRemove = [
					formData.upload_biometric_back.inputKey,
					formData.upload_biometric_front.inputKey,
					formData.upload_regular_id.inputKey,
				];
				dispatch(Actions.removeFromSignupForm(keysToRemove));
				setFirstTry(true);
			} else if (key === fields.gender.key) {
				dispatch(Actions.updateSignupForm({ [key]: parseInt(val) }));
			}
		} else if (type === "file") {
			const uploadedFile = e.target?.files[0];

			if (!uploadedFile) {
				return;
			}

			const handleBase64ConversionSuccess = (data) => {
				dispatch(Actions.updateSignupForm({ [key]: { file: uploadedFile, base64: data } }));
			};

			convertToBase64(uploadedFile, handleBase64ConversionSuccess);
		} else if (formData[name]?.keyboardType === "tel" && !isNumber(val) && val?.length > 0) {
			return;
		} else {
			dispatch(Actions.updateSignupForm({ [key]: val }));
		}
	};

	const renderContentBasedOnType = () => {
		const selectedIdType = signupForm[formData[fields.idTypes.name]?.inputKey];
		const regularIdType = fields.idTypes.options.regular.value;
		const biometricIdType = fields.idTypes.options.biometric.value;

		switch (selectedIdType) {
			case regularIdType:
				return renderRegularIdUpload();
			case biometricIdType:
				return renderBiometricIdUpload();
			default:
				return null;
		}
	};

	const renderUploadButton = (fieldName) => {
		if (!fieldName) {
			return null;
		}

		const fileData = signupForm[fieldName.name];
		const isInvalidSecondTry = !firstTry && formData[fieldName.name].isValid?.valid === false;
		const isFileExceededError = !!formData[fieldName.name].isValid?.isLimitExceeded;

		const handleFileChange = (e) => {
			try {
				const selectedFile = e.target.files[0];
				const fileSize = selectedFile.size;

				// Reset validation
				setFormData((prevState) => {
					return {
						...prevState,
						[fieldName.name]: { ...prevState[fieldName.name], isValid: { valid: true, errMsg: "", isLimitExceeded: false } },
					};
				});

				// Check if the file size exceeds the maximum allowed size
				if (fileSize > maxAllowedImageSizeInBytes) {
					e.target.value = null; // Reset the file input

					setFormData((prevState) => {
						return {
							...prevState,
							[fieldName.name]: {
								...prevState[fieldName.name],
								isValid: {
									valid: false,
									errMsg: translate("upload_id_manually_size_error", { size: formatBytes(maxAllowedImageSizeInBytes) }),
									isLimitExceeded: true,
								},
							},
						};
					});

					return;
				}

				handleInputChange(e);
			} catch (error) {
				console.log(error);
			}
		};

		return (
			<div className="upload-id-manually-button-file-container">
				<div className="upload-id-manually-button-file">
					<div className="upload-id-manually-button-label">{translate(fieldName.label)}</div>

					<div className="buttons-wrapper">
						<UploadButton
							className="upload-button"
							id={fieldName.id}
							name={fieldName.name}
							text={translate(fieldName.defaultText)}
							typesToAccept={fieldName.typesToAccept}
							onChange={handleFileChange}
							uploadedName={fileData?.file?.name}
							handlePreviewButtonClick={() => handlePreviewButtonClick(fileData)}
						/>

						{!!fileData && renderRemoveDocumentButton(fieldName)}
					</div>
				</div>

				{(isInvalidSecondTry || isFileExceededError) && (
					<div className="upload-id-manually-error-message">{formData[fieldName.name].isValid.errMsg}</div>
				)}
			</div>
		);
	};

	const renderRegularIdUpload = () => {
		return <div className="upload-id-manually-button-wrapper">{renderUploadButton(fields.uploadRegularId)}</div>;
	};

	const renderBiometricIdUpload = () => {
		return (
			<div className="upload-id-manually-button-wrapper">
				{renderUploadButton(fields.uploadBiometricFront)}
				{renderUploadButton(fields.uploadBiometricBack)}
			</div>
		);
	};

	const handlePreviewButtonClick = (fileData) => {
		const popupPayload = { type: popupTypes.DOCUMENT_PREVIEW, payload: fileData };
		dispatch(Actions.addPopup(popupPayload));
	};

	const renderRemoveDocumentButton = (fieldName) => {
		const handleRemoveButtonClick = () => {
			dispatch(Actions.removeFromSignupForm([fieldName.name]));
		};

		return (
			<button className="remove-document-button" type="button" onClick={handleRemoveButtonClick}>
				<img src={deleteDocumentIcon} alt="" />
			</button>
		);
	};

	const handleNextButtonClick = (e) => {
		if (e) {
			e.preventDefault();
		}

		setFirstTry(false);

		let validationResult = validateForm(true);

		if (validationResult) {
			const selectedIdType = parseInt(signupForm[fields.idTypes.key]);

			const onSuccess = (response) => {
				if (response.status === 1) {
					dispatch(Actions.setWizardSteps({ isBackDisabled: BOOLEAN_VALUES.true }));
					navigateToNextStep(validationResult, location, navigate);
				}
			};

			const onFailure = (response) => {
				if (response.status === 0) {
					handleServerError(response);
				}
			};

			const getDocumentFileType = (file = {}) => file?.type?.split(["/"])[1];

			const getPayloadBasedOnIdType = () => {
				switch (selectedIdType) {
					case ID_TYPES.REGULAR:
						const regularIdFileData = signupForm[fields.uploadRegularId.key] ?? {};

						return {
							[fields.uploadRegularId.apiKeys.documentFile]: regularIdFileData.base64,
							[fields.uploadRegularId.apiKeys.fileType]: getDocumentFileType(regularIdFileData.file),
						};
					case ID_TYPES.BIOMETRIC:
						const biometricFrontFileData = signupForm[fields.uploadBiometricFront.key] ?? {};
						const biometricBackFileData = signupForm[fields.uploadBiometricBack.key] ?? {};

						return {
							[fields.uploadBiometricFront.apiKeys.documentFile]: biometricFrontFileData.base64,
							[fields.uploadBiometricFront.apiKeys.fileType]: getDocumentFileType(biometricFrontFileData.file),
							[fields.uploadBiometricBack.apiKeys.documentFile]: biometricBackFileData.base64,
							[fields.uploadBiometricBack.apiKeys.fileType]: getDocumentFileType(biometricBackFileData.file),
						};
					default:
						return {};
				}
			};

			const props = {
				onSuccess,
				onFailure,
				payload: {
					[fields.idTypes.key]: selectedIdType,
					[fields.firstName.apiKey]: signupForm[fields.firstName.key],
					[fields.lastName.apiKey]: signupForm[fields.lastName.key],
					[fields.idNumber.apiKey]: signupForm[fields.idNumber.key],
					[fields.issueDate.apiKey]: convertDateToTimestamp(signupForm[fields.issueDate.key]),
					[fields.birthDate.apiKey]: convertDateToTimestamp(signupForm[fields.birthDate.key]),
					[fields.gender.apiKey]: parseInt(signupForm[fields.gender.key]),
					...(signupForm[fields.expiryDate.key]
						? { [fields.expiryDate.apiKey]: convertDateToTimestamp(signupForm[fields.expiryDate.key]) }
						: {}),
					...getPayloadBasedOnIdType(),
				},
			};

			Api.uploadManualIdData(props);
		}
	};

	const renderExpiryDateDisclaimer = () => {
		const selectedIdType = signupForm[formData[fields.idTypes.name]?.inputKey];
		const regularIdType = fields.idTypes.options.regular.value;

		if (selectedIdType !== regularIdType) {
			return null;
		}

		return <div className="upload-id-manually-expire-date-disclaimer">{HtmlParser(translate("manual_id_expiry_date_disclaimer"))}</div>;
	};

	return (
		<>
			<div className="upload-id-manually">
				<HeaderTitle>{translate("upload_id_manually_header")}</HeaderTitle>
				<Subheader>{translate("upload_id_manually_subheader")}</Subheader>

				<Hint>
					{isTextExists(headerText) ? <div className="hint-header">{headerText}</div> : null}
					{isTextExists(contentText) ? <div className="hint-content">{contentText}</div> : null}
				</Hint>

				<RadioButton
					type={fields.idTypes.type}
					label={translate(fields.idTypes.header)}
					options={fields.idTypes.options}
					name={fields.idTypes.name}
					onChange={handleInputChange}
					value={signupForm[fields.idTypes.key]}
					showError={!firstTry && formData[fields.idTypes.name].isValid?.valid === false}
					errorMessage={formData[fields.idTypes.name].isValid?.errMsg}
					isShort={true}
				/>

				{renderContentBasedOnType()}

				<div className="full-name-wrapper">
					<span className="full-name-label">{translate("manual_id_full_name_label")}</span>

					<div className="full-name-inputs">
						<TextInput
							label={translate(fields.firstName.label)}
							placeholder={translate(fields.firstName.placeholder)}
							name={fields.firstName.name}
							value={signupForm[fields.firstName.key] || ""}
							onChange={handleInputChange}
							keyboardType={fields.firstName.keyboardType}
							showError={!firstTry && formData[fields.firstName.name].isValid?.valid === false}
							errorMessage={formData[fields.firstName.name].isValid?.errMsg}
							isShort
						/>

						<TextInput
							label={translate(fields.lastName.label)}
							placeholder={translate(fields.lastName.placeholder)}
							name={fields.lastName.name}
							value={signupForm[fields.lastName.key] || ""}
							onChange={handleInputChange}
							keyboardType={fields.lastName.keyboardType}
							showError={!firstTry && formData[fields.lastName.name].isValid?.valid === false}
							errorMessage={formData[fields.lastName.name].isValid?.errMsg}
							isShort
						/>
					</div>
				</div>

				<TextInput
					label={translate(fields.idNumber.label)}
					placeholder={translate(fields.idNumber.placeholder)}
					name={fields.idNumber.name}
					value={signupForm[fields.idNumber.key] || ""}
					onChange={handleInputChange}
					keyboardType={fields.idNumber.keyboardType}
					showError={!firstTry && formData[fields.idNumber.name].isValid?.valid === false}
					errorMessage={formData[fields.idNumber.name].isValid?.errMsg}
				/>

				<DatePicker
					label={translate(fields.birthDate.label)}
					name={fields.birthDate.name}
					value={signupForm[fields.birthDate.key] || ""}
					onChange={handleInputChange}
					showError={!firstTry && formData[fields.birthDate.name].isValid?.valid === false}
					errorMessage={formData[fields.birthDate.name].isValid?.errMsg}
					max={convertTimestampToDate(Date.now())}
				/>

				<DatePicker
					label={translate(fields.issueDate.label)}
					name={fields.issueDate.name}
					value={signupForm[fields.issueDate.key] || ""}
					onChange={handleInputChange}
					showError={!firstTry && formData[fields.issueDate.name].isValid?.valid === false}
					errorMessage={formData[fields.issueDate.name].isValid?.errMsg}
					max={convertTimestampToDate(Date.now())}
				/>

				<DatePicker
					label={translate(fields.expiryDate.label)}
					name={fields.expiryDate.name}
					value={signupForm[fields.expiryDate.key] || ""}
					onChange={handleInputChange}
					showError={!firstTry && formData[fields.expiryDate.name].isValid?.valid === false}
					errorMessage={formData[fields.expiryDate.name].isValid?.errMsg}
					max={convertTimestampToDate(MAX_VALID_TS)}
					isShort={true}
				/>

				{renderExpiryDateDisclaimer()}

				<RadioButton
					type={fields.gender.type}
					label={translate(fields.gender.header)}
					options={fields.gender.options}
					name={fields.gender.name}
					value={signupForm[fields.gender.key]}
					showError={!firstTry && formData[fields.gender.name].isValid?.valid === false}
					errorMessage={formData[fields.gender.name].isValid?.errMsg}
					onChange={handleInputChange}
				/>
			</div>

			<Button isNext onClick={handleNextButtonClick} />
		</>
	);
};

export default UploadIdManually;
