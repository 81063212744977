import Store from "../redux/index";

import { validateOccupationsForm } from "containers/WizardSteps/OccupationAreas";
import routesMap from "./route-map";

import businessTypeFromHome from "../assets/icons/business_selling_type_from_home.svg";
import businessTypeFromHomeSelected from "../assets/icons/business_selling_type_from_home_selected.svg";
import businessTypeSellingPoint from "../assets/icons/business_selling_type_selling_point.svg";
import businessTypeSellingPointSelected from "../assets/icons/business_selling_type_selling_point_selected.svg";
import congratulationsImage from "../assets/icons/congratulations.svg";
import manualIdGenderFemale from "../assets/icons/manual_id_female.png";
import manualIdGenderMale from "../assets/icons/manual_id_male.svg";
import smsContact from "../assets/icons/sms_contact.svg";
import whatsAppContact from "../assets/icons/whatsapp_contact.svg";
import facebookLogo from "../assets/logos/platforms/facebook_logo.svg";
import instagramLogo from "../assets/logos/platforms/instagram_logo.svg";
import wwwLogo from "../assets/logos/platforms/www_logo.svg";

export const DELAY_DEBOUNCE = 100;
const ALLOWED_IMAGES_TYPES = "image/jpeg,image/png";

export const MANUAL_ID_UPLOAD_KEY = "id_verification_failed";
export const ID_TYPES = { BIOMETRIC: 1, REGULAR: 0 };

// * ACCESS REDUX TO GET DIFFERENT DATA
const getReduxValue = (slice, key = false) => {
	const state = Store.getState();
	const splittedKey = key ? key.split(".") : false;
	let reduxValue = state[slice];

	if (!key) {
		return reduxValue;
	}

	if (reduxValue) {
		for (let item of splittedKey) {
			reduxValue = reduxValue?.[item];
		}

		return reduxValue;
	}

	setTimeout(() => {
		getReduxValue(key, slice);
	}, 750);
};

// * DEFAULT BOOLEAN VALUES TO KEEP CONSISTENCY
export const BOOLEAN_VALUES = {
	true: 1,
	false: 0,
};

// * DIFFERENT TYPES OF POPUP PAYLOADS
export const POPUP_PAYLOADS = {
	tooManyOtpRetries: {
		text: "popup_too_many_otp_tries_error",
		btnText: "popup_too_many_otp_tries_button",
	},
	contactCustomerService: {
		header: "popup_contact_customer_service_header",
		text: "popup_contact_customer_service_error",
		isCustomerService: BOOLEAN_VALUES.true,
	},
	businessExists: {
		text: "popup_business_exists_header",
		optionsType: "stacked",
		name: "business_exists_options",
		options: {
			newBusiness: {
				id: "popup_business_exists_create_new",
				text: "popup_business_exists_create_new",
			},
			reminder: {
				id: "popup_business_exists_remind",
				text: "popup_business_exists_remind",
			},
		},
	},
};

export const CONTACT_TYPES = {
	whatsapp: {
		text: "contact_us_whatsapp",
		icon: whatsAppContact,
		alt: "contact_us_whatsapp_alt",
		get phoneNumber() {
			const phoneNumber = getReduxValue("translations", "customer_service_whatsapp_phone");
			const formattedWhatsAppPhone = "+972" + phoneNumber?.slice(1).replace("-", "");
			return formattedWhatsAppPhone;
		},
		get link() {
			return `https://wa.me/${this.phoneNumber}`;
		},
	},
	sms: {
		text: "contact_us_sms",
		icon: smsContact,
		alt: "contact_us_sms_alt",
		get phoneNumber() {
			const phoneNumber = getReduxValue("translations", "customer_service_sms_phone");
			const formattedPhoneNumber = "+972" + phoneNumber?.slice(1).replace("-", "");
			return formattedPhoneNumber;
		},
		get androidLink() {
			return `sms:${this.phoneNumber}?body=`;
		},
		get appleLink() {
			return `sms:${this.phoneNumber}&body=`;
		},
	},
};

export const CUSTOMER_VALIDATION_OTP = {
	phoneNumber: {
		label: "otp_phone_input_label",
		placeholder: "otp_phone_input_placeholder",
		key: "phone",
		name: "phone",
		id: "phone",
		keyboardType: "tel",
		validationRules: [
			() => {
				const isLeadOccupation = getReduxValue("isLeadOccupationEdit");

				// if editing lead occupation - no validation is needed
				if (isLeadOccupation) {
					return ["no_validation"];
				}

				return ["cell"];
			},
		],
	},
	phoneValidationCheckbox: {
		key: "phone_validation_checkbox",
		name: "phone_validation_checkbox",
		id: "phone_validation_checkbox",
		type: "rounded",
		label: "otp_phone_call_checkbox_label",
		isRequired: false,
	},
	otpCode: {
		key: "otp_code",
		name: "otp_code",
		lengthDoesNotMatchError: "otp_wrong_length_error_message",
		wrongCodeError: "otp_wrong_code_error_message",
		resendLabel: "otp_inputs_sub_label",
		isValidated: "is_otp_validated",
	},
};

export const BUSINESS_INFO_TYPE = {
	businessType: {
		key: "business_type",
		name: "business_type",
		id: "business_type",
		type: "stacked-spaced",
		label: "business_info_type_selection",
		options: {
			exemptDealer: {
				get value() {
					return getReduxValue("gd", "CheckBusiness.business_info_type_exempt_dealer");
				},
				text: "business_info_type_exempt_dealer",
				name: "exemptDealer",
				get id() {
					return `${BUSINESS_INFO_TYPE.businessType.id}${this.value}`;
				},
			},
			licensedDealer: {
				get value() {
					return getReduxValue("gd", "CheckBusiness.business_info_type_licensed_dealer");
				},
				text: "business_info_type_licensed_dealer",
				name: "licensedDealer",
				get id() {
					return `${BUSINESS_INFO_TYPE.businessType.id}${this.value}`;
				},
			},
			proprietaryLimitedCompany: {
				get value() {
					return getReduxValue("gd", "CheckBusiness.business_info_type_proprietary_limited_company");
				},
				text: "business_info_type_proprietary_limited_company",
				name: "proprietaryLimitedCompany",
				get id() {
					return `${BUSINESS_INFO_TYPE.businessType.id}${this.value}`;
				},
			},
			partnership: {
				get value() {
					return getReduxValue("gd", "CheckBusiness.business_info_type_partnership");
				},
				text: "business_info_type_partnership",
				name: "partnership",
				get id() {
					return `${BUSINESS_INFO_TYPE.businessType.id}${this.value}`;
				},
			},
			nonProfitOrganization: {
				get value() {
					return getReduxValue("gd", "CheckBusiness.business_info_type_non_profit_organization");
				},
				text: "business_info_type_non_profit_organization",
				name: "nonProfitOrganization",
				get id() {
					return `${BUSINESS_INFO_TYPE.businessType.id}${this.value}`;
				},
			},
			cooperativeSociety: {
				get value() {
					return getReduxValue("gd", "CheckBusiness.business_info_type_cooperative_society");
				},
				text: "business_info_type_cooperative_society",
				name: "cooperativeSociety",
				get id() {
					return `${BUSINESS_INFO_TYPE.businessType.id}${this.value}`;
				},
			},
		},
		validationRules: [
			() => {
				const isValid =
					parseInt(getReduxValue("signupForm", inputFields[routesMap.businessInfo.subRoutes.type.key].businessType.key) ?? 0) > 0;
				return { isValid: isValid, errMsg: `${BUSINESS_INFO_TYPE.businessType.label}` };
			},
		],
	},
	businessNumber: {
		setOfValidationRules: () => {
			let validationMap = new Map();

			validationMap.set(BUSINESS_INFO_TYPE.businessType.options.proprietaryLimitedCompany.name, ["not_empty", "plc_business_number"]);
			validationMap.set(BUSINESS_INFO_TYPE.businessType.options.partnership.name, ["not_empty", "other_business_number"]);
			validationMap.set(BUSINESS_INFO_TYPE.businessType.options.nonProfitOrganization.name, ["not_empty", "other_business_number"]);
			validationMap.set(BUSINESS_INFO_TYPE.businessType.options.cooperativeSociety.name, ["not_empty", "other_business_number"]);
			validationMap.set(BUSINESS_INFO_TYPE.businessType.options.exemptDealer.name, ["not_empty", "id"]);
			validationMap.set(BUSINESS_INFO_TYPE.businessType.options.licensedDealer.name, ["not_empty", "id"]);

			return validationMap;
		},
		key: "business_number",
		id: "business_number",
		name: "business_number",
		keyboardType: "tel",
		label: "business_info_type_number_label",
		placeholder: "business_info_type_number_placeholder",
		validationRules: () => {
			let setOfRules = inputFields[routesMap.businessInfo.subRoutes.type.key].businessNumber.setOfValidationRules();
			let businessType = getReduxValue("signupForm", inputFields[routesMap.businessInfo.subRoutes.type.key].businessType.key);
			let validationName = Object.values(BUSINESS_INFO_TYPE.businessType.options).find((o) => o.value === businessType).name;

			return setOfRules.get(validationName);
		},
		isRequired: () => parseInt(getReduxValue("signupForm", inputFields[routesMap.businessInfo.subRoutes.type.key].businessType.key) ?? 0) > 0,
	},
	shareHolders: {
		key: "share_holders_num",
		// id: "share_holders",
		// name: "share_holders",
		// keyboardType: "tel",
		// label: "business_info_type_share_holders_label",
		// placeholder: "business_info_type_share_holders_placeholder",
		// validationRules: ["not_empty", "share_holders"],
		// isRequired: () =>
		//     getReduxValue(inputFields[routesMap.businessInfo.subRoutes.type.key].businessType.key) ===
		//     inputFields[routesMap.businessInfo.subRoutes.type.key].businessType.options[3].value,
	},
};

const BUSINESS_INFO_NAME = {
	headerTitle: {
		label: "business_info_name_header",
	},
	businessName: {
		key: "he_name",
		name: "he_name",
		id: "he_name",
		label: "business_info_name_label",
		placeholder: "business_info_name_placeholder",
		validationRules: ["not_empty", "hebrew_business_name"],
	},
	isSameName: {
		key: "same_as_credit",
		name: "same_as_credit",
		id: "same_as_credit",
		header: "business_info_name_is_same_credit_statement_header",
		label: "business_info_name_is_same_credit_statement_label",
		options: [
			{
				id: "is_same_for_credit_statement1",
				value: BOOLEAN_VALUES.true,
				text: "business_info_name_is_same_credit_statement_true",
			},
			{
				id: "is_same_for_credit_statement2",
				value: BOOLEAN_VALUES.false,
				text: "business_info_name_is_same_credit_statement_false",
			},
		],
		type: "options",
		validationRules: ["required_radio"],
	},
	businessNameEnglish: {
		key: "en_name",
		name: "en_name",
		id: "en_name",
		label: "business_info_name_in_english_label",
		subLabel: "business_info_name_in_english_sub_label",
		placeholder: "business_info_name_in_english_placeholder",
		validationRules: ["not_empty", "english_business_name"],
	},
	differentName: {
		key: "name_for_credit",
		name: "name_for_credit",
		id: "name_for_credit",
		label: "business_info_name_for_statement_label",
		placeholder: "business_info_name_for_statement_placeholder",
		hintHeader: "business_info_name_hint_header",
		hintText: "business_info_name_hint_text",
		validationRules: ["not_empty", "hebrew_business_name"],
		isRequired: () => {
			let isDifferentName = getReduxValue("signupForm", inputFields[routesMap.businessInfo.subRoutes.name.key].isSameName.key);
			return isDifferentName !== BOOLEAN_VALUES.true;
		},
	},
};

export const BUSINESS_INFO_ADDRESS = {
	headerTitle: {
		label: "business_info_address_header",
	},
	isManualAddress: {
		key: "is_manual_address",
	},
	businessAddress: {
		apiKey: "address",
		key: "business_address",
		name: "business_address",
		id: "business_address",
		label: "business_info_address_select_label",
		placeholder: "business_info_address_select_placeholder",
		addressNotFound: {
			text: "business_info_address_not_found",
			name: "address-not-found",
		},
		requiredKeys: {
			streetNumber: "house_number",
			streetName: "street",
			cityName: "city",
			postalCode: "postal_code",
		},
		validationRules: [
			() => {
				const requiredKeys = BUSINESS_INFO_ADDRESS.businessAddress.requiredKeys;

				const validationsBasedKeys = {
					streetNumber: (val) => Boolean(Number(val)),
				};

				for (let requiredKey in requiredKeys) {
					let isValueInRedux = getReduxValue("signupForm", requiredKeys[requiredKey]);
					let isValidationExists = typeof validationsBasedKeys[requiredKey] === "function";
					let isValueValid = isValidationExists ? validationsBasedKeys[requiredKey](isValueInRedux) : true;

					if (!isValueInRedux || !isValueValid) {
						return { isValid: false, errMsg: `business_info_address_error_message`, addRequired: false };
					}
				}

				return { isValid: true, errMsg: `business_info_address_error_message`, addRequired: false };
			},
		],
	},
	addressCity: {
		key: "city",
		name: "city",
		id: "city",
		label: "business_address_city_label",
		validationRules: ["not_empty", "hebrew_characters"],
		isRequired: () => getReduxValue("signupForm", inputFields[routesMap.businessInfo.subRoutes.address.key].isManualAddress.key),
	},
	addressStreet: {
		key: "street",
		name: "street",
		id: "street",
		label: "business_address_street_label",
		validationRules: ["not_empty", "address_street"],
		isRequired: () => getReduxValue("signupForm", inputFields[routesMap.businessInfo.subRoutes.address.key].isManualAddress.key),
	},
	addressNumber: {
		key: "house_number",
		name: "house_number",
		id: "house_number",
		keyboardType: "tel",
		label: "business_address_number_label",
		validationRules: ["not_empty", "is_number"],
		isRequired: () => getReduxValue("signupForm", inputFields[routesMap.businessInfo.subRoutes.address.key].isManualAddress.key),
	},
	addressPostalCode: {
		key: "postal_code",
		name: "postal_code",
		id: "postal_code",
		keyboardType: "tel",
		label: "business_name_postal_code_label",
		validationRules: ["not_empty", "postal_code"],
		isPostalRequiredKey: "is_postal_required",
		isRequired: () =>
			getReduxValue("signupForm", BUSINESS_INFO_ADDRESS.addressPostalCode.isPostalRequiredKey) ||
			getReduxValue("signupForm", inputFields[routesMap.businessInfo.subRoutes.address.key].isManualAddress.key),
	},
	businessSellingType: {
		key: "from_home",
		name: "from_home",
		id: "from_home",
		label: "business_info_selling_type_label",
		header: "business_info_selling_type_header",
		type: "stacked-spaced-image",
		placeholder: "business_info_selling_type_placeholder",
		options: {
			fromHome: {
				id: "business_selling_type1",
				value: 1,
				text: "business_info_selling_from_home",
				img: {
					regular: businessTypeFromHome,
					selected: businessTypeFromHomeSelected,
				},
			},
			fromSellingPoint: {
				id: "business_selling_type2",
				value: 0,
				text: "business_info_selling_point",
				img: {
					regular: businessTypeSellingPoint,
					selected: businessTypeSellingPointSelected,
				},
			},
		},
		validationRules: [
			() => {
				const reduxValue = getReduxValue("signupForm", inputFields[routesMap.businessInfo.subRoutes.address.key].businessSellingType.name);
				const isValid = reduxValue !== undefined && reduxValue !== null && reduxValue !== "";
				return { isValid, errMsg: `business_info_selling_type_error_message`, addRequired: false };
			},
		],
	},
	get unusedKeys() {
		let keys = [];
		keys.push(this.addressCity.key);
		keys.push(this.addressStreet.key);
		keys.push(this.addressNumber.key);
		keys.push(this.addressPostalCode.key);

		return keys;
	},
};

export const BUSINESS_INFO_OWNER = {
	headerTitle: {
		label: "business_info_owner_header",
	},
	isFormRequired: () => {
		let currentBusinessType = getReduxValue("signupForm", BUSINESS_INFO_TYPE.businessType.key);
		let numberOfShareHolders = Number(getReduxValue("signupForm", BUSINESS_INFO_TYPE.shareHolders.key));

		let isRequired =
			currentBusinessType === BUSINESS_INFO_TYPE.businessType.options.licensedDealer.value ||
			currentBusinessType === BUSINESS_INFO_TYPE.businessType.options.exemptDealer.value ||
			(currentBusinessType === BUSINESS_INFO_TYPE.businessType.options.proprietaryLimitedCompany.value &&
				(numberOfShareHolders === 1 || numberOfShareHolders === 0));

		return isRequired;
	},
	ownerFirstName: {
		apiKey: "first_name",
		key: "owner_first_name",
		name: "owner_first_name",
		id: "owner_first_name",
		inputLabel: "business_owner_full_name_label",
		label: "business_owner_full_name_placeholder",
		placeholder: "business_owner_full_name_placeholder",
		validationRules: ["not_empty", "first_name"],
		isRequired: () => BUSINESS_INFO_OWNER.isFormRequired(),
	},
	ownerLastName: {
		apiKey: "last_name",
		key: "owner_last_name",
		name: "owner_last_name",
		id: "owner_last_name",
		label: "business_owner_last_name_label",
		placeholder: "business_owner_last_name_placeholder",
		validationRules: ["not_empty", "last_name"],
		isRequired: () => BUSINESS_INFO_OWNER.isFormRequired(),
	},
	ownerIdNumber: {
		key: "owner_id",
		name: "owner_id",
		id: "owner_id",
		label: "business_owner_id_number_label",
		placeholder: "business_owner_id_number_placeholder",
		keyboardType: "tel",
		validationRules: ["not_empty", "id"],
		isRequired: () => BUSINESS_INFO_OWNER.isFormRequired(),
	},
	ownerPhoneNumber: {
		key: CUSTOMER_VALIDATION_OTP.phoneNumber.key,
		label: "business_owner_phone_number_label",
		subLabel: "business_owner_phone_number_sub_label",
	},
	ownerEmailAddress: {
		apiKey: "email",
		keyboardType: "email",
		key: "owner_email",
		name: "owner_email",
		id: "owner_email",
		label: "business_owner_email_address_label",
		placeholder: "business_owner_email_address_placeholder",
		keyboardType: "email",
		validationRules: ["not_empty", "email"],
		isRequired: () => BUSINESS_INFO_OWNER.isFormRequired(),
	},
	ownerRelationsWithPublicFigure: {
		key: "public_figure",
		name: "public_figure",
		id: "public_figure",
		label: "business_owner_public_figure_relations_label",
		header: "business_owner_public_figure_relations_header",
		hintHeader: "business_owner_public_figure_relations_hint_header",
		hintText: "business_owner_public_figure_relations_hint_text",
		type: "options",
		options: [
			{ id: `business_owner_public_figure_relations1`, value: BOOLEAN_VALUES.true, text: "business_owner_public_figure_relations_yes" },
			{ id: `business_owner_public_figure_relations2`, value: BOOLEAN_VALUES.false, text: "business_owner_public_figure_relations_no" },
		],
		validationRules: [
			() => {
				const reduxValue = getReduxValue(
					"signupForm",
					inputFields[routesMap.businessInfo.subRoutes.owner.key].ownerRelationsWithPublicFigure.name,
				);
				const isValid = reduxValue !== undefined && reduxValue !== null && reduxValue !== "";
				return { isValid, errMsg: `business_owner_public_figure_relations_error_message`, addRequired: false };
			},
		],
		isRequired: () => BUSINESS_INFO_OWNER.isFormRequired(),
	},
	unusedKeys: function () {
		let keys = [];
		keys.push(this.ownerFirstName.key);
		keys.push(this.ownerLastName.key);
		keys.push(this.ownerIdNumber.key);
		keys.push(this.ownerEmailAddress.key);
		keys.push(this.ownerRelationsWithPublicFigure.key);
		return keys;
	},
};

export const CONTACT_INFO_GENERAL = {
	headerTitle: {
		label: "contact_info_general_header",
	},
	isFormRequired: () => {
		let currentBusinessType = getReduxValue("signupForm", BUSINESS_INFO_TYPE.businessType.key);
		let numberOfShareHolders = Number(getReduxValue("signupForm", BUSINESS_INFO_TYPE.shareHolders.key));

		let isRequired =
			currentBusinessType === BUSINESS_INFO_TYPE.businessType.options.nonProfitOrganization.value ||
			currentBusinessType === BUSINESS_INFO_TYPE.businessType.options.partnership.value ||
			currentBusinessType === BUSINESS_INFO_TYPE.businessType.options.cooperativeSociety.value ||
			(currentBusinessType === BUSINESS_INFO_TYPE.businessType.options.proprietaryLimitedCompany.value && numberOfShareHolders > 1);

		return isRequired;
	},

	contactFirstName: {
		key: "first_name",
		name: "first_name",
		id: "first_name",
		label: "contact_info_full_name_label",
		header: "contact_info_full_name_header",
		placeholder: "contact_info_full_name_placeholder",
		validationRules: ["not_empty", "first_name"],
		isRequired: () => CONTACT_INFO_GENERAL.isFormRequired(),
	},
	contactLastName: {
		key: "last_name",
		name: "last_name",
		id: "last_name",
		label: "contact_info_last_name_label",
		placeholder: "contact_info_last_name_placeholder",
		validationRules: ["not_empty", "last_name"],
		isRequired: () => CONTACT_INFO_GENERAL.isFormRequired(),
	},
	contactPhoneNumber: {
		key: "customers_contact_phone",
		apiKey: "phone",
		name: "customers_contact_phone",
		id: "customers_contact_phone",
		label: "contact_info_phone_number_label",
		placeholder: "contact_info_phone_number_placeholder",
		keyboardType: "tel",
		validationRules: ["not_empty", "cell"],
		isRequired: () => CONTACT_INFO_GENERAL.isFormRequired(),
	},
	contactEmailAddress: {
		key: "customers_contact_email",
		name: "customers_contact_email",
		id: "customers_contact_email",
		apiKey: "email",
		label: "contact_info_email_address_label",
		placeholder: "contact_info_email_address_placeholder",
		keyboardType: "email",
		validationRules: ["not_empty", "email"],
		isRequired: () => CONTACT_INFO_GENERAL.isFormRequired(),
	},
	contactBusinessRole: {
		key: "role",
		name: "role",
		id: "role",
		label: "contact_info_business_role_label",
		validationRules: [
			() => {
				return getReduxValue("signupForm", "roleId") ?? false;
			},
		],
		isRequired: () => CONTACT_INFO_GENERAL.isFormRequired(),
	},
	unusedKeys: function () {
		let keys = [];
		keys.push(this.contactFirstName.key);
		keys.push(this.contactLastName.key);
		keys.push(this.contactPhoneNumber.key);
		keys.push(this.contactEmailAddress.key);
		keys.push(this.contactBusinessRole.key);
		return keys;
	},
};

export const CONTACT_INFO_CONFIRMATION = {
	headerTitle: {
		label: CONTACT_INFO_GENERAL.headerTitle.label,
	},
	contactBusinessHint: {
		header: "contact_info_hint_header",
		text: "contact_info_hint_content",
		ownerData: {
			phoneNumber: BUSINESS_INFO_OWNER.ownerPhoneNumber.key,
			emailAddress: BUSINESS_INFO_OWNER.ownerEmailAddress.key,
		},
		contactData: {
			phoneNumber: CONTACT_INFO_GENERAL.contactPhoneNumber.key,
			emailAddress: CONTACT_INFO_GENERAL.contactEmailAddress.key,
		},
	},
	sendThisInfoToCustomers: {
		key: "is_send_contact_info",
		name: "is_send_contact_info",
		id: "is_send_contact_info",
		label: "is_send_contact_info_label",
		header: "is_send_contact_info_header",
		type: "options-column",
		options: {
			true: {
				id: "is_send_contact_info1",
				value: BOOLEAN_VALUES.true,
				text: "is_send_contact_info_allow_text",
			},
			false: {
				id: "is_send_contact_info2",
				value: BOOLEAN_VALUES.false,
				text: "is_send_contact_info_different_info_text",
			},
		},
		validationRules: [
			() => {
				const isLeadOccupation = getReduxValue("isLeadOccupationEdit");

				// if editing lead occupation - no validation is needed
				if (isLeadOccupation) {
					return ["no_validation"];
				}

				const reduxValue = getReduxValue(
					"signupForm",
					inputFields[routesMap.contactInfo.subRoutes.confirmation.key].sendThisInfoToCustomers.name,
				);
				const isValid = reduxValue !== undefined && reduxValue !== null && reduxValue !== "";
				return { isValid, errMsg: `is_send_contact_info_error_message`, addRequired: false };
			},
		],
	},
	differentEmailAddress: {
		apiKey: "email",
		keyboardType: "email",
		key: "different_email_address",
		name: "different_email_address",
		id: "different_email_address",
		label: "different_email_address_label",
		keyboardType: "email",
		validationRules: ["not_empty", "email"],
		isRequired: () => getReduxValue("signupForm", CONTACT_INFO_CONFIRMATION.sendThisInfoToCustomers.key) === BOOLEAN_VALUES.false,
	},
	differentPhoneNumber: {
		apiKey: "phone",
		key: "different_phone_number",
		name: "different_phone_number",
		id: "different_phone_number",
		label: "different_phone_number_label",
		keyboardType: "tel",
		validationRules: ["not_empty", "cell"],
		isRequired: () => getReduxValue("signupForm", CONTACT_INFO_CONFIRMATION.sendThisInfoToCustomers.key) === BOOLEAN_VALUES.false,
	},
};

const OCCUPATION_AREAS_GENERAL = {
	headerTitle: {
		label: "occupation_areas_header",
	},
	mainAreas: {
		id: "main_occupation",
		key: "main_occupation",
		name: "main_occupation",
		label: "occupation_areas_dropdown_label",
		placeholder: "occupation_areas_dropdown_placeholder",
		validationRules: [
			() => {
				const isValid = getReduxValue("occupationAreaData", `autocomplete.${OCCUPATION_AREAS_GENERAL.mainAreas.key}`) ?? false;

				return {
					isValid: isValid,
					errMsg: "occupation_areas_dropdown_error_message",
					addRequired: false,
				};
			},
		],
	},
	recursiveAreas: {
		id: "recursive_areas",
		key: "recursive_areas",
		name: "recursive_areas",
		validationRules: [
			() => {
				const translations = getReduxValue("translations", "");
				return validateOccupationsForm(getReduxValue("occupationAreaData"), Store.dispatch, translations);
			},
		],
	},
	managementApproval: {
		key: "management_approval",
		name: "management_approval",
		id: "management_approval",
		header: "occupation_area_management_approval_header",
		label: "occupation_area_management_approval_label",
		options: [
			{
				get id() {
					return `${OCCUPATION_AREAS_GENERAL.managementApproval.id}${this.value}`;
				},
				value: BOOLEAN_VALUES.true,
				text: "occupation_area_management_approval_true",
			},
			{
				get id() {
					return `${OCCUPATION_AREAS_GENERAL.managementApproval.id}${this.value}`;
				},
				value: BOOLEAN_VALUES.false,
				text: "occupation_area_management_approval_false",
			},
		],
		type: "options",
		validationRules: ["required_radio"],
		isRequired: () => {
			return (
				getReduxValue("signupForm", BUSINESS_INFO_TYPE.businessType.key) ===
				BUSINESS_INFO_TYPE.businessType.options.nonProfitOrganization.value
			);
		},
	},
	receiveDonations: {
		key: "receive_donations",
		name: "receive_donations",
		id: "receive_donations",
		header: "occupation_area_receive_donations_header",
		label: "occupation_area_receive_donations_label",
		options: [
			{
				get id() {
					return `${OCCUPATION_AREAS_GENERAL.receiveDonations.id}${this.value}`;
				},
				value: BOOLEAN_VALUES.true,
				text: "occupation_area_receive_donations_true",
			},
			{
				get id() {
					return `${OCCUPATION_AREAS_GENERAL.receiveDonations.id}${this.value}`;
				},
				value: BOOLEAN_VALUES.false,
				text: "occupation_area_receive_donations_false",
			},
		],
		type: "options",
		validationRules: ["required_radio"],
		isRequired: () => {
			return (
				getReduxValue("signupForm", BUSINESS_INFO_TYPE.businessType.key) ===
				BUSINESS_INFO_TYPE.businessType.options.nonProfitOrganization.value
			);
		},
	},
};

export const PRODUCT_INFO_GENERAL = {
	headerTitle: {
		label: "product_info_general_header",
		sub: "product_info_general_sub_header",
	},
	question_customer: {
		type: "rounded",
		header: "product_info_general_customer_type",
		name: "question_customer",
		apiKey: "customers",
		get key() {
			return PRODUCT_INFO_GENERAL.question_customer.name;
		},
		options: {
			business: {
				id: "business",
				text: "product_customer_type_business",
				get bitwiseValue() {
					return parseInt(getReduxValue("gd", "IsChecked.your_customers.product_customer_type_business"));
				},
				get name() {
					return PRODUCT_INFO_GENERAL.question_customer.name;
				},
			},
			private: {
				id: "private",
				text: "product_customer_type_private",
				get bitwiseValue() {
					return parseInt(getReduxValue("gd", "IsChecked.your_customers.product_customer_type_private"));
				},
				get name() {
					return PRODUCT_INFO_GENERAL.question_customer.name;
				},
			},
		},
		validationRules: [
			() => {
				let isValid = getReduxValue("signupForm", inputFields[routesMap.productInfo.subRoutes.general.key].question_customer.name) > 0;
				return { isValid, errMsg: "product_info_general_customer_error_message", addRequired: false };
			},
		],
	},
	question_selling: {
		type: "rounded",
		header: "product_info_general_selling_type",
		name: "question_selling",
		apiKey: "selling",
		get key() {
			return PRODUCT_INFO_GENERAL.question_selling.name;
		},
		options: {
			products: {
				id: "products",
				text: "product_selling_type_products",
				get bitwiseValue() {
					return parseInt(getReduxValue("gd", "IsChecked.you_sell.product_selling_type_products"));
				},
				get name() {
					return PRODUCT_INFO_GENERAL.question_selling.name;
				},
			},

			services: {
				id: "services",
				text: "product_selling_type_services",
				get bitwiseValue() {
					return parseInt(getReduxValue("gd", "IsChecked.you_sell.product_selling_type_services"));
				},
				get name() {
					return PRODUCT_INFO_GENERAL.question_selling.name;
				},
			},
		},
		validationRules: [
			() => {
				let isValid = getReduxValue("signupForm", inputFields[routesMap.productInfo.subRoutes.general.key].question_selling.name) > 0;
				return { isValid, errMsg: "product_info_general_selling_error_message", addRequired: false };
			},
		],
	},
	question_customers_living: {
		type: "rounded",
		header: "product_info_general_customer_location",
		name: "question_customers_living",
		apiKey: "customers_living",
		get key() {
			return PRODUCT_INFO_GENERAL.question_customers_living.name;
		},
		options: {
			israel: {
				id: "israel",
				text: "product_customer_location_israel",
				get bitwiseValue() {
					return parseInt(getReduxValue("gd", "IsChecked.your_customers_live.product_customer_location_israel"));
				},
				get name() {
					return PRODUCT_INFO_GENERAL.question_customers_living.name;
				},
			},

			abroad: {
				id: "abroad",
				text: "product_customer_location_abroad",
				get bitwiseValue() {
					return parseInt(getReduxValue("gd", "IsChecked.your_customers_live.product_customer_location_abroad"));
				},
				get name() {
					return PRODUCT_INFO_GENERAL.question_customers_living.name;
				},
			},
		},
		validationRules: [
			() => {
				let isValid =
					getReduxValue("signupForm", inputFields[routesMap.productInfo.subRoutes.general.key].question_customers_living.name) > 0;
				return { isValid, errMsg: "product_info_general_customer_living_error_message", addRequired: false };
			},
		],
	},
};

export const PRODUCT_INFO_DURATION = {
	headerTitle: PRODUCT_INFO_GENERAL.headerTitle,
	serviceDuration: {
		key: "service_len",
		name: "service_len",
		id: "service_len",
		label: "product_service_duration_label",
		subLabel: "product_service_duration_sub_label",
		options: {
			oneTime: {
				get id() {
					return `${PRODUCT_INFO_DURATION.serviceDuration.id}${this.value}`;
				},
				get value() {
					return parseInt(getReduxValue("gd", "ServiceProductsQuestions.length_of_service.product_service_duration_one_time"));
				},
				text: "product_service_duration_one_time",
			},
			continual: {
				get id() {
					return `${PRODUCT_INFO_DURATION.serviceDuration.id}${this.value}`;
				},
				get value() {
					return parseInt(getReduxValue("gd", "ServiceProductsQuestions.length_of_service.product_service_duration_continual"));
				},
				text: "product_service_duration_continual",
			},
		},
		type: "stacked-no-check",
		validationRules: ["required_radio"],
		isRequired: () => {
			let sellingBitwise = getReduxValue("signupForm", PRODUCT_INFO_GENERAL.question_selling.key);
			let requiredServiceBitwise = PRODUCT_INFO_GENERAL.question_selling.options.services.bitwiseValue;

			return (sellingBitwise & requiredServiceBitwise) > 0;
		},
	},
	productDeliveryTime: {
		key: "delivery_time",
		name: "delivery_time",
		id: "delivery_time",
		label: "product_delivery_time_label",
		options: {
			immediate: {
				get id() {
					return `${PRODUCT_INFO_DURATION.productDeliveryTime.id}${this.value}`;
				},
				get value() {
					return parseInt(getReduxValue("gd", "ServiceProductsQuestions.product_delivery_time.product_delivery_time_option1"));
				},
				text: "product_delivery_time_option1",
			},
			upToSevenDays: {
				get id() {
					return `${PRODUCT_INFO_DURATION.productDeliveryTime.id}${this.value}`;
				},
				get value() {
					return parseInt(getReduxValue("gd", "ServiceProductsQuestions.product_delivery_time.product_delivery_time_option2"));
				},
				text: "product_delivery_time_option2",
			},
			overSevenDays: {
				get id() {
					return `${PRODUCT_INFO_DURATION.productDeliveryTime.id}${this.value}`;
				},
				get value() {
					return parseInt(getReduxValue("gd", "ServiceProductsQuestions.product_delivery_time.product_delivery_time_option3"));
				},
				text: "product_delivery_time_option3",
			},
		},
		type: "stacked-no-check",
		validationRules: ["required_radio"],
		isRequired: () => {
			let sellingBitwise = getReduxValue("signupForm", PRODUCT_INFO_GENERAL.question_selling.key);
			let requiredServiceBitwise = PRODUCT_INFO_GENERAL.question_selling.options.products.bitwiseValue;

			return (sellingBitwise & requiredServiceBitwise) > 0;
		},
	},
	productsOrigin: {
		type: "rounded",
		key: "products_from",
		name: "products_from",
		id: "products_from",
		label: "products_origin_type_label",
		subLabel: "products_origin_type_sub_label",
		options: {
			own_made: {
				text: "products_origin_type_own_made",
				get name() {
					return PRODUCT_INFO_DURATION.productsOrigin.name;
				},
				get id() {
					return `${PRODUCT_INFO_DURATION.productsOrigin.id}${this.bitwiseValue}`;
				},
				get bitwiseValue() {
					return parseInt(getReduxValue("gd", "ServiceProductsQuestions.the_source_products.products_origin_type_own_made"));
				},
			},
			purchased_locally: {
				text: "products_origin_type_purchased_locally",
				get name() {
					return PRODUCT_INFO_DURATION.productsOrigin.name;
				},
				get id() {
					return `${PRODUCT_INFO_DURATION.productsOrigin.id}${this.bitwiseValue}`;
				},
				get bitwiseValue() {
					return parseInt(getReduxValue("gd", "ServiceProductsQuestions.the_source_products.products_origin_type_purchased_locally"));
				},
			},
			purchased_abroad: {
				id: "purchased_abroad",
				text: "products_origin_type_purchased_abroad",
				get name() {
					return PRODUCT_INFO_DURATION.productsOrigin.name;
				},
				get id() {
					return `${PRODUCT_INFO_DURATION.productsOrigin.id}${this.bitwiseValue}`;
				},
				get bitwiseValue() {
					return parseInt(getReduxValue("gd", "ServiceProductsQuestions.the_source_products.products_origin_type_purchased_abroad"));
				},
			},
		},
		validationRules: [
			() => {
				let isValid = getReduxValue("signupForm", PRODUCT_INFO_DURATION.productsOrigin.name) > 0;
				return { isValid, errMsg: "products_origin_type_error_message", addRequired: false };
			},
		],
		isRequired: () => {
			let sellingBitwise = getReduxValue("signupForm", PRODUCT_INFO_GENERAL.question_selling.key);
			let requiredServiceBitwise = PRODUCT_INFO_GENERAL.question_selling.options.products.bitwiseValue;

			return (sellingBitwise & requiredServiceBitwise) > 0;
		},
	},
};

const PRODUCT_INFO_FINANCE = {
	headerTitle: {
		label: "product_finance_header",
		subheader: "product_finance_subheader",
	},
	averageTransaction: {
		key: "avg_amount_transaction",
		name: "avg_amount_transaction",
		id: "avg_amount_transaction",
		label: "avg_amount_transaction_label",
		type: "stacked-spaced-dynamically",
		options: {
			optionOne: {
				id: "avg_amount_transaction_option_one",
				text: "avg_amount_transaction_option_one",
				get id() {
					return `${PRODUCT_INFO_FINANCE.averageTransaction.id}${this.value}`;
				},
				get value() {
					return parseInt(getReduxValue("gd", "NumbersQuestions.average_transaction_amount.avg_amount_transaction_option_one"));
				},
			},
			optionTwo: {
				text: "avg_amount_transaction_option_two",
				get id() {
					return `${PRODUCT_INFO_FINANCE.averageTransaction.id}${this.value}`;
				},
				get value() {
					return parseInt(getReduxValue("gd", "NumbersQuestions.average_transaction_amount.avg_amount_transaction_option_two"));
				},
			},
			optionThree: {
				text: "avg_amount_transaction_option_three",
				get id() {
					return `${PRODUCT_INFO_FINANCE.averageTransaction.id}${this.value}`;
				},
				get value() {
					return parseInt(getReduxValue("gd", "NumbersQuestions.average_transaction_amount.avg_amount_transaction_option_three"));
				},
			},
			optionFour: {
				text: "avg_amount_transaction_option_four",
				get id() {
					return `${PRODUCT_INFO_FINANCE.averageTransaction.id}${this.value}`;
				},
				get value() {
					return parseInt(getReduxValue("gd", "NumbersQuestions.average_transaction_amount.avg_amount_transaction_option_four"));
				},
			},
			optionFive: {
				text: "avg_amount_transaction_option_five",
				get id() {
					return `${PRODUCT_INFO_FINANCE.averageTransaction.id}${this.value}`;
				},
				get value() {
					return parseInt(getReduxValue("gd", "NumbersQuestions.average_transaction_amount.avg_amount_transaction_option_five"));
				},
			},
		},
		validationRules: ["required_radio"],
	},
	monthlyClearing: {
		key: "month_amount",
		name: "month_amount",
		id: "month_amount",
		label: "month_amount_label",
		header: "month_amount_header",
		type: "stacked-spaced-dynamically",
		options: {
			optionOne: {
				text: "month_amount_option_one",
				get id() {
					return `${PRODUCT_INFO_FINANCE.monthlyClearing.id}${this.value}`;
				},
				get value() {
					return parseInt(getReduxValue("gd", "NumbersQuestions.estimated_monthly_settlement.month_amount_option_one"));
				},
			},
			optionTwo: {
				text: "month_amount_option_two",
				get id() {
					return `${PRODUCT_INFO_FINANCE.monthlyClearing.id}${this.value}`;
				},
				get value() {
					return parseInt(getReduxValue("gd", "NumbersQuestions.estimated_monthly_settlement.month_amount_option_two"));
				},
			},
			optionThree: {
				text: "month_amount_option_three",
				get id() {
					return `${PRODUCT_INFO_FINANCE.monthlyClearing.id}${this.value}`;
				},
				get value() {
					return parseInt(getReduxValue("gd", "NumbersQuestions.estimated_monthly_settlement.month_amount_option_three"));
				},
			},
			optionFour: {
				text: "month_amount_option_four",
				get id() {
					return `${PRODUCT_INFO_FINANCE.monthlyClearing.id}${this.value}`;
				},
				get value() {
					return parseInt(getReduxValue("gd", "NumbersQuestions.estimated_monthly_settlement.month_amount_option_four"));
				},
			},
		},
		validationRules: ["required_radio"],
	},
	fundingSources: {
		key: "finance_source",
		name: "finance_source",
		id: "finance_source",
		label: "finance_source_label",
		subLabel: "finance_source_sub_label",
		type: "stacked-spaced",
		hintHeader: "finance_source_hint_header",
		hintText: "finance_source_hint_text",

		// * IF BUSINESS TYPE IS 'NON PROFIT ORGANIZATION' THEN WE SHOULD SEND A DEFAULT VALUE BECAUSE OTHER OPTIONS ARE NOT ACCESSIBLE
		get defaultNonProfitOrganization() {
			return getReduxValue("gd", "NumbersQuestions.business_financing_sources.funding_source_donation");
		},
		options: {
			equity: {
				text: "funding_source_equity",
				get name() {
					return PRODUCT_INFO_FINANCE.fundingSources.name;
				},
				get id() {
					return `${PRODUCT_INFO_FINANCE.fundingSources.id}${this.bitwiseValue}`;
				},
				get bitwiseValue() {
					return parseInt(getReduxValue("gd", "NumbersQuestions.business_financing_sources.funding_source_equity"));
				},
			},
			loan: {
				id: "loan",
				text: "funding_source_loan",
				get name() {
					return PRODUCT_INFO_FINANCE.fundingSources.name;
				},
				get id() {
					return `${PRODUCT_INFO_FINANCE.fundingSources.id}${this.bitwiseValue}`;
				},
				get bitwiseValue() {
					return parseInt(getReduxValue("gd", "NumbersQuestions.business_financing_sources.funding_source_loan"));
				},
			},
			gift: {
				id: "gift",
				text: "funding_source_gift",
				get name() {
					return PRODUCT_INFO_FINANCE.fundingSources.name;
				},
				get id() {
					return `${PRODUCT_INFO_FINANCE.fundingSources.id}${this.bitwiseValue}`;
				},
				get bitwiseValue() {
					return parseInt(getReduxValue("gd", "NumbersQuestions.business_financing_sources.funding_source_gift"));
				},
			},
			investors: {
				id: "investors",
				text: "funding_source_investors",
				get name() {
					return PRODUCT_INFO_FINANCE.fundingSources.name;
				},
				get id() {
					return `${PRODUCT_INFO_FINANCE.fundingSources.id}${this.bitwiseValue}`;
				},
				get bitwiseValue() {
					return parseInt(getReduxValue("gd", "NumbersQuestions.business_financing_sources.funding_source_investors"));
				},
			},
		},
		validationRules: [
			() => {
				let isValid = getReduxValue("signupForm", PRODUCT_INFO_FINANCE.fundingSources.key) > 0;
				return { isValid: isValid, errMsg: "finance_funding_source_error_message", addRequired: false };
			},
		],
	},
};

const PRODUCT_INFO_PLATFORMS = {
	headerTitle: {
		label: "product_platforms_header",
		sub: "product_platforms_sub_header",
		inputsHeader: "product_platforms_website_label",
		noPlatformsError: "product_platforms_platforms_is_must",
	},
	hintHeader: "product_platforms_hint_header",
	hintText: "product_platforms_hint_text",
	businessWebsite: {
		type: "image",
		icon: wwwLogo,
		iconAlt: "WWW Logo",
		key: "website",
		name: "website",
		id: "website",
		validationRules: ["general_website_url"],
		isRequired: () => {
			const isCheckboxChecked = getReduxValue("signupForm", PRODUCT_INFO_PLATFORMS.noPlatformsCheckbox.key) === BOOLEAN_VALUES.true;
			const isWebsiteValueAvailable = getReduxValue("signupForm", PRODUCT_INFO_PLATFORMS.businessWebsite.key) ?? false;
			const isFacebookValueAvailable = getReduxValue("signupForm", PRODUCT_INFO_PLATFORMS.businessFacebook.key) ?? false;
			const isInstagramValueAvailable = getReduxValue("signupForm", PRODUCT_INFO_PLATFORMS.businessInstagram.key) ?? false;
			const isMust = (!isCheckboxChecked && !isFacebookValueAvailable && !isInstagramValueAvailable) || isWebsiteValueAvailable;
			return isMust;
		},
	},
	businessFacebook: {
		type: "image",
		icon: facebookLogo,
		iconAlt: "Facebook Logo",
		key: "facebook",
		name: "facebook",
		id: "facebook",
		validationRules: ["facebook_url"],
		isRequired: () => {
			const isCheckboxChecked = getReduxValue("signupForm", PRODUCT_INFO_PLATFORMS.noPlatformsCheckbox.key) === BOOLEAN_VALUES.true;
			const isWebsiteValueAvailable = getReduxValue("signupForm", PRODUCT_INFO_PLATFORMS.businessWebsite.key) ?? false;
			const isFacebookValueAvailable = getReduxValue("signupForm", PRODUCT_INFO_PLATFORMS.businessFacebook.key) ?? false;
			const isInstagramValueAvailable = getReduxValue("signupForm", PRODUCT_INFO_PLATFORMS.businessInstagram.key) ?? false;
			const isMust = (!isCheckboxChecked && !isWebsiteValueAvailable && !isInstagramValueAvailable) || isFacebookValueAvailable;
			return isMust;
		},
	},
	businessInstagram: {
		type: "image",
		icon: instagramLogo,
		iconAlt: "Instagram Logo",
		key: "instagram",
		name: "instagram",
		id: "instagram",
		validationRules: ["instagram_url"],
		isRequired: () => {
			const isCheckboxChecked = getReduxValue("signupForm", PRODUCT_INFO_PLATFORMS.noPlatformsCheckbox.key) === BOOLEAN_VALUES.true;
			const isWebsiteValueAvailable = getReduxValue("signupForm", PRODUCT_INFO_PLATFORMS.businessWebsite.key) ?? false;
			const isFacebookValueAvailable = getReduxValue("signupForm", PRODUCT_INFO_PLATFORMS.businessFacebook.key) ?? false;
			const isInstagramValueAvailable = getReduxValue("signupForm", PRODUCT_INFO_PLATFORMS.businessInstagram.key) ?? false;
			const isMust = (!isCheckboxChecked && !isFacebookValueAvailable && !isWebsiteValueAvailable) || isInstagramValueAvailable;
			return isMust;
		},
	},
	noPlatformsCheckbox: {
		type: "squared",
		key: "dont_have_social",
		name: "dont_have_social",
		id: "dont_have_social",
		label: "no_business_platforms_label",
		isRequired: false,
	},
	businessDescription: {
		apiKey: "description",
		key: "social_description",
		name: "social_description",
		id: "social_description",
		label: "business_description_label",
		placeholder: "business_description_placeholder",
		validationRules: ["not_empty", "textarea_description"],
		isRequired: () => {
			return getReduxValue("occupationAreaData", "businessDescription") ? true : false;
		},
	},
	unusedKeys: function () {
		let keys = [];
		keys.push(this.businessWebsite.key);
		keys.push(this.businessFacebook.key);
		keys.push(this.businessInstagram.key);
		return keys;
	},
};

export const FINANCE_INFO_BANK_TYPE = {
	headerTitle: {
		label: "finance_info_bank_type_header",
	},
	bankTypes: {
		label: "finance_info_bank_type_selection_label",
		header: "finance_info_bank_type_selection_header",
		type: "stacked-spaced-image",
		key: "bank_code",
		name: "bank_code",
		validationRules: ["required_radio"],
	},
};

const FINANCE_INFO_BANK_DATA = {
	headerTitle: {
		label: "finance_info_bank_data_header",
	},
	hint: {
		header: "finance_info_bank_data_hint_header",
		text: "finance_info_bank_data_hint_content",
	},
	selectedBank: {
		text: "finance_info_bank_data_selected_template",
	},
	branchOptions: {
		id: "branch_code",
		key: "branch_code",
		name: "branch_code",
		label: "finance_info_branch_code_label",
		placeholder: "finance_info_branch_code_placeholder",
		validationRules: [
			() => {
				let isValid = getReduxValue("signupForm", FINANCE_INFO_BANK_DATA.branchOptions.key) ?? false;
				return { isValid: isValid, errMsg: "finance_info_branch_code_error_message", addRequired: false };
			},
		],
	},
	bankAccountNumber: {
		label: "finance_info_bank_account_number_label",
		key: "account_number",
		name: "account_number",
		id: "account_number",
		keyboardType: "tel",
		validationRules: ["not_empty", "bank_account_number"],
	},
	bankAccountOwners: {
		label: "finance_info_bank_account_owners_label",
		key: "name_of_account_holder",
		name: "name_of_account_holder",
		id: "name_of_account_holder",
		validationRules: ["not_empty", "bank_account_owners"],
	},
};

export const MANUAL_ID_INFO_GENERAL = {
	// isFormRequired: () => {
	//     let currentBusinessType = getReduxValue("signupForm", BUSINESS_INFO_TYPE.businessType.key);
	//     let numberOfShareHolders = Number(getReduxValue("signupForm", BUSINESS_INFO_TYPE.shareHolders.key));
	//     let isRequired =
	//         currentBusinessType === BUSINESS_INFO_TYPE.businessType.options.licensedDealer.value ||
	//         currentBusinessType === BUSINESS_INFO_TYPE.businessType.options.exemptDealer.value ||
	//         (currentBusinessType === BUSINESS_INFO_TYPE.businessType.options.proprietaryLimitedCompany.value && numberOfShareHolders === 1);
	//     return !isRequired;
	// },
	// idIssueDate: {
	//     label: "id_issue_date_label",
	//     placeholder: "id_issue_date_placeholder",
	//     key: "id_issue_date",
	//     name: "id_issue_date",
	//     id: "id_issue_date",
	//     keyboardType: "tel",
	//     isRequired: () => ID_INFO_GENERAL.isFormRequired(),
	//     validationRules: ["not_empty"],
	// },
};

export const UPLOAD_OTHER_DOCUMENTS = {
	headerTitle: {
		label: "upload_other_documents_label",
		subLabel: "upload_other_documents_sub_label",
	},
	uploadDocuments: {
		key: "upload_document_button",
		name: "upload_document_button",
		id: "upload_document_button",
		label: "upload_document_button_label",
		defaultText: "upload_document_button_default_text",
		typesToAccept: "image/jpeg",
		apiKeys: {
			documentFile: "file",
			fileType: "file_type",
		},
		documentData: "document_data",
		isRequired: () => getReduxValue("occupationAreaData", "documents")?.length > 0,
		validationRules: [
			() => {
				const isBase64Exists = !!getReduxValue("signupForm", UPLOAD_OTHER_DOCUMENTS.uploadDocuments.documentData)?.base64;
				const isFileDataExists = !!getReduxValue("signupForm", UPLOAD_OTHER_DOCUMENTS.uploadDocuments.documentData)?.file;

				return { isValid: isBase64Exists && isFileDataExists, errMsg: "upload_other_documents_error_message", addRequired: false };
			},
		],
	},
};

export const ID_INFO_GENERAL = {
	isManualUpload: () => getReduxValue("signupForm", MANUAL_ID_UPLOAD_KEY) ?? false,
	headerTitle: {
		label: "id_validation_header",
		sub: "id_validation_sub_header",
	},
	idUpload: {
		validationKey: "is_id_valid",
		key: "is_status_valid",
		name: "is_status_valid",
		id: "is_status_valid",
		isRequired: () => {
			const isManualIdUpload = ID_INFO_GENERAL.isManualUpload();
			return BUSINESS_INFO_OWNER.isFormRequired() && !isManualIdUpload;
		},
		validationRules: [
			() => {
				const isFormRequired = BUSINESS_INFO_OWNER.isFormRequired();

				if (!isFormRequired) {
					return true;
				}

				const isValid = getReduxValue("signupForm", ID_INFO_GENERAL.idUpload.validationKey) === BOOLEAN_VALUES.true;
				return isValid;
			},
		],
	},
	idTypes: {
		key: "id_type",
		name: "id_type",
		id: "id_type",
		type: "stacked-no-check",
		options: {
			regular: {
				id: "id_type2",
				value: ID_TYPES.REGULAR,
				text: "id_type_regular",
			},
			biometric: {
				id: "id_type1",
				value: ID_TYPES.BIOMETRIC,
				text: "id_type_biometric",
			},
		},
		validationRules: ["required_radio"],
		isRequired: () => {
			const isManualIdUpload = ID_INFO_GENERAL.isManualUpload();
			return BUSINESS_INFO_OWNER.isFormRequired() && isManualIdUpload;
		},
	},
	uploadRegularId: {
		key: "upload_regular_id",
		name: "upload_regular_id",
		id: "upload_regular_id",
		label: "upload_regular_id_label",
		defaultText: "upload_regular_id_default_text",
		typesToAccept: ALLOWED_IMAGES_TYPES,
		apiKeys: {
			documentFile: "front_file",
			fileType: "front_file_type",
		},
		documentData: "upload_regular_id",
		isRequired: () => {
			const isManualIdUpload = ID_INFO_GENERAL.isManualUpload();
			const selectedIdType = getReduxValue("signupForm", ID_INFO_GENERAL.idTypes.key) ?? "";
			return BUSINESS_INFO_OWNER.isFormRequired() && isManualIdUpload && selectedIdType === ID_TYPES.REGULAR;
		},
		validationRules: [
			() => {
				const isBase64Exists = !!getReduxValue("signupForm", ID_INFO_GENERAL.uploadRegularId.name)?.base64;
				const isFileDataExists = !!getReduxValue("signupForm", ID_INFO_GENERAL.uploadRegularId.name)?.file;

				return { isValid: isBase64Exists && isFileDataExists, errMsg: "upload_regular_id_error_message", addRequired: false };
			},
		],
	},
	uploadBiometricFront: {
		key: "upload_biometric_front",
		name: "upload_biometric_front",
		id: "upload_biometric_front",
		label: "upload_biometric_front_label",
		defaultText: "upload_biometric_front_default_text",
		typesToAccept: ALLOWED_IMAGES_TYPES,
		apiKeys: {
			documentFile: "front_file",
			fileType: "front_file_type",
		},
		documentData: "upload_biometric_front",
		isRequired: () => {
			const isManualIdUpload = ID_INFO_GENERAL.isManualUpload();
			const selectedIdType = getReduxValue("signupForm", ID_INFO_GENERAL.idTypes.key) ?? "";
			return BUSINESS_INFO_OWNER.isFormRequired() && isManualIdUpload && selectedIdType === ID_TYPES.BIOMETRIC;
		},
		validationRules: [
			() => {
				const isBase64Exists = !!getReduxValue("signupForm", ID_INFO_GENERAL.uploadBiometricFront.name)?.base64;
				const isFileDataExists = !!getReduxValue("signupForm", ID_INFO_GENERAL.uploadBiometricFront.name)?.file;

				return { isValid: isBase64Exists && isFileDataExists, errMsg: "upload_biometric_front_error_message", addRequired: false };
			},
		],
	},
	uploadBiometricBack: {
		key: "upload_biometric_back",
		name: "upload_biometric_back",
		id: "upload_biometric_back",
		label: "upload_biometric_back_label",
		defaultText: "upload_biometric_back_default_text",
		typesToAccept: ALLOWED_IMAGES_TYPES,
		apiKeys: {
			documentFile: "back_file",
			fileType: "back_file_type",
		},
		documentData: "upload_biometric_back",
		isRequired: () => {
			const isManualIdUpload = ID_INFO_GENERAL.isManualUpload();
			const selectedIdType = getReduxValue("signupForm", ID_INFO_GENERAL.idTypes.key) ?? "";
			return BUSINESS_INFO_OWNER.isFormRequired() && isManualIdUpload && selectedIdType === ID_TYPES.BIOMETRIC;
		},
		validationRules: [
			() => {
				const isBase64Exists = !!getReduxValue("signupForm", ID_INFO_GENERAL.uploadBiometricBack.name)?.base64;
				const isFileDataExists = !!getReduxValue("signupForm", ID_INFO_GENERAL.uploadBiometricBack.name)?.file;

				return { isValid: isBase64Exists && isFileDataExists, errMsg: "upload_biometric_back_error_message", addRequired: false };
			},
		],
	},
	firstName: {
		apiKey: "first_name",
		key: "manual_id_first_name",
		name: "manual_id_first_name",
		id: "manual_id_first_name",
		placeholder: "manual_id_first_name_placeholder",
		validationRules: ["not_empty", "first_name"],
		isRequired: () => {
			const isManualIdUpload = ID_INFO_GENERAL.isManualUpload();
			return BUSINESS_INFO_OWNER.isFormRequired() && isManualIdUpload;
		},
	},
	lastName: {
		apiKey: "last_name",
		key: "manual_id_last_name",
		name: "manual_id_last_name",
		id: "manual_id_last_name",
		placeholder: "manual_id_last_name_placeholder",
		validationRules: ["not_empty", "last_name"],
		isRequired: () => {
			const isManualIdUpload = ID_INFO_GENERAL.isManualUpload();
			return BUSINESS_INFO_OWNER.isFormRequired() && isManualIdUpload;
		},
	},
	idNumber: {
		apiKey: "owner_id",
		key: "manual_id_owner_id",
		name: "manual_id_owner_id",
		id: "manual_id_owner_id",
		label: "manual_id_owner_id_label",
		placeholder: "manual_id_owner_id_placeholder",
		keyboardType: "tel",
		validationRules: ["not_empty", "id"],
		isRequired: () => {
			const isManualIdUpload = ID_INFO_GENERAL.isManualUpload();
			return BUSINESS_INFO_OWNER.isFormRequired() && isManualIdUpload;
		},
	},
	birthDate: {
		apiKey: "birth_date",
		key: "manual_id_birth_date",
		name: "manual_id_birth_date",
		id: "manual_id_birth_date",
		label: "manual_id_birth_date_label",
		validationRules: ["not_empty", "timestamp"],
		isRequired: () => {
			const isManualIdUpload = ID_INFO_GENERAL.isManualUpload();
			return BUSINESS_INFO_OWNER.isFormRequired() && isManualIdUpload;
		},
	},
	issueDate: {
		apiKey: "issue_date",
		key: "manual_id_issue_date",
		name: "manual_id_issue_date",
		id: "manual_id_issue_date",
		label: "manual_id_issue_date_label",
		validationRules: ["not_empty", "timestamp"],
		isRequired: () => {
			const isManualIdUpload = ID_INFO_GENERAL.isManualUpload();
			return BUSINESS_INFO_OWNER.isFormRequired() && isManualIdUpload;
		},
	},
	expiryDate: {
		apiKey: "expiry_date",
		key: "manual_id_expiry_date",
		name: "manual_id_expiry_date",
		id: "manual_id_expiry_date",
		label: "manual_id_expiry_date_label",
		validationRules: ["not_empty", "timestamp"],
		isRequired: false,
	},
	gender: {
		apiKey: "gender",
		key: "manual_id_gender",
		name: "manual_id_gender",
		id: "manual_id_gender",
		header: "manual_id_gender_header",
		type: "stacked-spaced-image",
		options: {
			female: {
				id: "manual_id_gender2",
				value: 0,
				text: "manual_id_gender_type_female",
				img: {
					regular: manualIdGenderFemale,
					selected: manualIdGenderFemale,
				},
			},
			male: {
				id: "manual_id_gender1",
				value: 1,
				text: "manual_id_gender_type_male",
				img: {
					regular: manualIdGenderMale,
					selected: manualIdGenderMale,
				},
			},
		},
		validationRules: [
			() => {
				const reduxValue = getReduxValue("signupForm", inputFields[routesMap.uploadDocuments.subRoutes.id.key].gender.name);
				const isValid = reduxValue !== undefined && reduxValue !== null && reduxValue !== "";
				return { isValid, errMsg: `manual_id_gender_error_message`, addRequired: false };
			},
		],
		isRequired: () => {
			const isManualIdUpload = ID_INFO_GENERAL.isManualUpload();
			return BUSINESS_INFO_OWNER.isFormRequired() && isManualIdUpload;
		},
	},
};

const AGREEMENT_TERMS_GENERAL = {
	headerTitle: {
		label: "agreement_terms_general_header",
	},
	terms: {
		base: "agreement_terms_general_base_text",
		extended: "approve_conditions_text",
	},
	agreeCheckbox: {
		type: "squared",
		key: "agree_to_terms",
		name: "agree_to_terms",
		id: "agree_to_terms",
		labels: {
			owner: "agree_to_terms_owner_label",
			contact: "agree_to_terms_contact_label",
		},
		validationRules: [
			() => {
				let isValid = getReduxValue("signupForm", AGREEMENT_TERMS_GENERAL.agreeCheckbox.key) === BOOLEAN_VALUES.true;
				return { isValid, errMsg: "agree_to_terms_missing_error_message" };
			},
		],
	},
	beneficiariesCheckbox: {
		type: "squared",
		key: "no_more_account_users",
		name: "no_more_account_users",
		id: "no_more_account_users",
		label: "absence_of_beneficiaries",
		validationRules: [
			() => {
				let isValid = getReduxValue("signupForm", AGREEMENT_TERMS_GENERAL.beneficiariesCheckbox.key) === BOOLEAN_VALUES.true;
				return { isValid, errMsg: "absence_of_beneficiaries_error_message", addRequired: false };
			},
		],
	},

	keys: {
		date: "date",
	},
};

export const AGREEMENT_TERMS_SUCCESS = {
	image: {
		main: congratulationsImage,
		alt: "success_image_alt",
	},
	texts: {
		main: "success_main_text",
		sub: "success_sub_text",
	},
};

export const inputFields = {
	[routesMap.customerValidation.subRoutes.otp.key]: CUSTOMER_VALIDATION_OTP,
	[routesMap.businessInfo.subRoutes.type.key]: BUSINESS_INFO_TYPE,
	[routesMap.businessInfo.subRoutes.name.key]: BUSINESS_INFO_NAME,
	[routesMap.businessInfo.subRoutes.address.key]: BUSINESS_INFO_ADDRESS,
	[routesMap.businessInfo.subRoutes.owner.key]: BUSINESS_INFO_OWNER,
	[routesMap.contactInfo.subRoutes.general.key]: CONTACT_INFO_GENERAL,
	[routesMap.contactInfo.subRoutes.confirmation.key]: CONTACT_INFO_CONFIRMATION,
	[routesMap.occupationAreas.subRoutes.general.key]: OCCUPATION_AREAS_GENERAL,
	[routesMap.productInfo.subRoutes.general.key]: PRODUCT_INFO_GENERAL,
	[routesMap.productInfo.subRoutes.duration.key]: PRODUCT_INFO_DURATION,
	[routesMap.productInfo.subRoutes.finance.key]: PRODUCT_INFO_FINANCE,
	[routesMap.productInfo.subRoutes.platforms.key]: PRODUCT_INFO_PLATFORMS,
	[routesMap.financeInfo.subRoutes.bank.key]: FINANCE_INFO_BANK_TYPE,
	[routesMap.financeInfo.subRoutes["bank-data"].key]: FINANCE_INFO_BANK_DATA,
	[routesMap.uploadDocuments.subRoutes.other.key]: UPLOAD_OTHER_DOCUMENTS,
	[routesMap.uploadDocuments.subRoutes.id.key]: ID_INFO_GENERAL,
	[routesMap.agreementTerm.subRoutes.general.key]: AGREEMENT_TERMS_GENERAL,
	[routesMap.agreementTerm.subRoutes.congratulations.key]: AGREEMENT_TERMS_SUCCESS,
};
