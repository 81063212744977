import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, Navigate, useSearchParams, useNavigate, useLocation } from "react-router-dom";
import Api from "api/requests";

//Import containers
import Popups from "popups";

//css
import "styles/app.scss";
import "styles/fonts.scss";

//routing constants
import DesktopContainer from "containers/desktop_container";
import MobileContainer from "containers/mobile_container";
import Loader from "./components/common/loaders/screen";
import Actions from "redux/actions";
import routesMap, { routeWrapper } from "constants/route-map";
import { mainRootRoutes } from "constants/main-routes";
import { au10tixObject } from "constants/au10tix";
import { contentRoutesObj } from "constants/content-routes";
import { BOOLEAN_VALUES, inputFields } from "constants/input-fields";
import { LEAD_TYPES } from "constants/lead-types";

const App = () => {
	// * HOW MANY TIMES A SCRIPT SHOULD RETRY TO LOAD ITSELF BEFORE IT STOPS
	const MAX_SCRIPTS_RETRIES = 5;

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const deviceState = useSelector((store) => store.deviceState);
	const requestingState = useSelector((store) => store.requestingState);
	const popupsArray = useSelector((store) => store.popupsArray);
	const isBackDisabled = useSelector((store) => store.wizardSteps?.isBackDisabled) ?? false;

	// a state variable to store both leadData and occupations data states for knowing when both are done
	const editLeadOccupationsState = useRef({ leadData: false, occupations: false });

	const [initialRequestsDone, setInitialRequestsDone] = useState(false);
	const [startingLoadingTime, setStartingLoadingTime] = useState(0);
	const [isAu10tixPath, setIsAu10tixPath] = useState(false);
	const [showLoader, setShowLoader] = useState(requestingState);

	useEffect(() => {
		// * SHOULD WE MAKE ADDITIONAL REQUESTS TO GET DATA LIKE OCCUPATIONS
		// * THIS IS TO EXCLUDE UNNECESSARY REQUESTS FOR SPECIFIC ROUTES (AU10TIX, ROLE OPTIONS AND ETC...)
		let shouldRequestAdditionalData = true;

		dispatch(Actions.setIsIframe(window.self !== window.top));
		loadGoogleTagManager(1, MAX_SCRIPTS_RETRIES);
		loadReCAPTCHA(1, MAX_SCRIPTS_RETRIES);

		handleLeadIdSteps();

		Api.generalDeclaration({ config: { method: "GET" } }).then((res) => {
			setInitialRequestsDone(true);

			const translations = res.data.ErrorsLangParam.langs;
			dispatch(Actions.setTranslations(translations));
		});

		const mainSubRoute = location.pathname.split("/")[1];
		const subRoute = location.pathname.split("/")[2];

		// * IF THE PATH IS AU10TIX => DO NOT VALIDATE IT AND JUST NAVIGATE TO IT
		if (mainSubRoute === au10tixObject.path) {
			setIsAu10tixPath(true);
			dispatch(Actions.setIsAu10tix(true));
			shouldRequestAdditionalData = false;
		}

		if (mainSubRoute === mainRootRoutes.introduction && subRoute === routesMap.introduction.subRoutes.begin.path) {
			dispatch(Actions.setIsInitialPage(true));
		}

		const isRouteContentPage = Object.values(contentRoutesObj).find((contentRoute) => contentRoute.path === mainSubRoute);
		if (isRouteContentPage) {
			dispatch(Actions.setWizardSteps({ hideSteps: BOOLEAN_VALUES.true }));
			dispatch(Actions.setIsContentPage(true));
			shouldRequestAdditionalData = false;
		}

		// * IF SHOULD REQUEST ADDITIONAL DATA => CONTINUE AND EXECUTE WHAT'S IN THE SCOPE
		if (shouldRequestAdditionalData) {
			Api.getRoleOptions({ config: { method: "GET" } });

			const onGetOccupationsSuccess = () => {
				const leadIdData = extractLeadIdData();
				const leadType = leadIdData?.type;

				if (leadType === LEAD_TYPES.EDIT_OCCUPATION.type) {
					editLeadOccupationsState.current.occupations = true;
					navigateToOccupationsPage();
				}
			};

			Api.getOccupations({ onSuccess: onGetOccupationsSuccess, config: { method: "GET" } });

			const { defaultMain, defaultSubRoute } = defaultRedirect();
			const fullPath = `/${defaultMain}/${defaultSubRoute}`;
			navigate(fullPath);
		}
	}, []);

	useEffect(() => {
		// * IF BACK BUTTON IS DISABLED => DON'T ALLOW TO USE THE BROWSERS BACK BUTTON
		const handlePopState = () => {
			if (!!isBackDisabled) {
				window.history.pushState(null, document.title, window.location.pathname);
			}
		};

		if (!!isBackDisabled) {
			window.history.pushState(null, document.title, window.location.pathname);
		}

		window.addEventListener("popstate", handlePopState);

		return () => window.removeEventListener("popstate", handlePopState);
	}, [isBackDisabled]);

	useEffect(() => {
		dispatch(Actions.setLoader(showLoader));
	}, [showLoader]);

	useEffect(() => {
		if (requestingState) {
			setShowLoader(requestingState);
			setStartingLoadingTime(new Date().getTime());
		} else {
			if (popupsArray.length > 0) {
				setShowLoader(false);
				return;
			}

			const currentTime = new Date().getTime();
			const diff = currentTime - startingLoadingTime;
			const minDelay = 1500;
			setTimeout(() => setShowLoader(requestingState), diff < minDelay ? minDelay - diff : 0);
		}
	}, [requestingState, popupsArray]);

	const extractLeadIdData = () => {
		// test url parameters based on known types to handle different states
		const regularLeadParam = searchParams.get(LEAD_TYPES.REGULAR_LEAD.urlParam);
		const editOccupationLeadParam = searchParams.get(LEAD_TYPES.EDIT_OCCUPATION.urlParam);

		switch (true) {
			case Boolean(regularLeadParam):
				return { type: LEAD_TYPES.REGULAR_LEAD.type, leadId: regularLeadParam };
			case Boolean(editOccupationLeadParam):
				return { type: LEAD_TYPES.EDIT_OCCUPATION.type, leadId: editOccupationLeadParam };
			default:
				return false;
		}
	};

	const handleLeadIdSteps = () => {
		const leadIdData = extractLeadIdData();

		if (!leadIdData) {
			return;
		}

		const leadType = leadIdData.type;

		switch (leadType) {
			case LEAD_TYPES.REGULAR_LEAD.type:
				handleRegularLead(leadIdData);
				break;
			case LEAD_TYPES.EDIT_OCCUPATION.type:
				handleEditOccupationLead(leadIdData);
				break;
			default:
				break;
		}
	};

	const handleRegularLead = (leadIdData) => {
		const leadId = leadIdData.leadId;

		dispatch(Actions.setLeadId(leadId));
		loadSeonScript(1, MAX_SCRIPTS_RETRIES, leadId);

		const onSuccess = (response) => {
			if (parseInt(response.status) === 1) {
				const responseData = response.data;
				dispatch(Actions.setIsLeadDataReady(true));

				if (responseData.expired === 1) {
					dispatch(Actions.setIsLeadExpired(true));
					return;
				}

				if (responseData.open_text) {
					dispatch(Actions.setOpenText(responseData.open_text));
				}
			}
		};

		const onFailure = (error) => {
			if (parseInt(error.status) === 0) {
				dispatch(Actions.setLeadId(false));
			}
		};

		Api.getStartLeadInfo({ payload: {}, onSuccess, onFailure });
	};

	const handleEditOccupationLead = (leadIdData) => {
		const leadId = leadIdData.leadId;
		dispatch(Actions.setLeadId(leadId));
		dispatch(Actions.setIsLeadOccupationEdit(true));

		const onSuccess = (response) => {
			if (parseInt(response.status) === 1) {
				editLeadOccupationsState.current.leadData = true;
				const responseData = response.data.lead_data;
				dispatch(Actions.updateSignupForm(responseData));
				navigateToOccupationsPage();
			}
		};

		const onFailure = (error) => {
			if (parseInt(error.status) === 0) {
				dispatch(Actions.setLeadId(false));
			}
		};

		const payload = { token: leadIdData.leadId };
		const props = { payload, onSuccess, onFailure };
		Api.getLeadData(props);
	};

	const navigateToOccupationsPage = () => {
		const { leadData = false, occupations = false } = editLeadOccupationsState.current;

		// if both leadData and occupations data are done - navigate to occupations screen
		if (leadData && occupations) {
			const mainPath = routesMap.occupationAreas.path;
			const subRoute = routesMap.occupationAreas.subRoutes.general.path;

			const fullPath = `/${mainPath}/${subRoute}`;
			navigate(fullPath);
			dispatch(Actions.setIsInitialPage(false));
		}
	};

	const defaultRedirect = () => {
		// * THIS FUNCTION CHECKS THE ROUTE MAP FOR THE DEFAULT ROUTE TO START WITH
		let finalRoute = {};

		for (let route in routesMap) {
			const subRoutes = routesMap[route]?.subRoutes;
			finalRoute.defaultMain = routesMap[route].path;

			if (routesMap[route].isMainDefault) {
				return finalRoute;
			}

			for (let subRoute in subRoutes) {
				const isDefault = subRoutes[subRoute]?.isMainDefault ?? false;

				if (isDefault) {
					finalRoute.defaultSubRoute = subRoute;
					return finalRoute;
				}
			}
		}

		return finalRoute;
	};

	const generateRoutes = () => {
		const nestedRoutes = (route) => {
			return (
				<Route exact key={route.key} path={route.path} element={routeWrapper}>
					<Route exact index element={<Navigate to={Object.keys(route.subRoutes)[0]} />} />
					{Object.keys(route.subRoutes).map((subRoute) => {
						let Component = route.subRoutes[subRoute].component;
						let componentFields = inputFields[route.subRoutes[subRoute].key];

						return (
							<Route
								exact
								key={route.subRoutes[subRoute].key}
								path={subRoute}
								element={
									<Component
										location={{
											key: route.subRoutes[subRoute].key,
											path: route.path,
											subRoute: subRoute,
										}}
										{...(componentFields && {
											fields: componentFields,
										})}
									/>
								}
							/>
						);
					})}
				</Route>
			);
		};

		const regularRoute = (route) => {
			let Component = route.component;
			let navigateTo = route.navigateTo;

			return (
				<Route
					key={route.key}
					path={route.path}
					element={
						<Component
							exact
							{...(navigateTo && {
								to: navigateTo,
							})}
						/>
					}
				/>
			);
		};

		const renderContentRoutes = (routeData) => {
			if (routeData.subRoutes) {
				return (
					<Route exact key={routeData.key} path={routeData.path} element={routeWrapper}>
						<Route exact index element={<Navigate to={Object.keys(routeData.subRoutes)[0]} />} />
						{Object.keys(routeData.subRoutes).map((subRoute) => {
							const Component = routeData.subRoutes[subRoute].component;
							return <Route exact key={routeData.subRoutes[subRoute].key} path={subRoute} element={<Component />} />;
						})}
					</Route>
				);
			} else {
				const Component = routeData.component;
				return <Route key={routeData.key} path={routeData.path} element={<Component exact />} />;
			}
		};

		return (
			<Routes>
				{Object.keys(routesMap).map((route) => {
					if (routesMap[route].subRoutes) {
						return nestedRoutes(routesMap[route]);
					} else {
						return regularRoute(routesMap[route]);
					}
				})}

				{Object.values(contentRoutesObj).map((routeData) => renderContentRoutes(routeData))}

				<Route exact key={au10tixObject.key} path={au10tixObject.path} element={routeWrapper}>
					<Route exact index element={<Navigate to={au10tixObject.subRoutes.success.path} />} />

					<Route
						exact
						key={au10tixObject.subRoutes.success.key}
						path={au10tixObject.subRoutes.success.path}
						element={au10tixObject.subRoutes.success.component}
					/>

					<Route
						exact
						key={au10tixObject.subRoutes.error.key}
						path={au10tixObject.subRoutes.error.path}
						element={au10tixObject.subRoutes.error.component}
					/>
				</Route>
			</Routes>
		);
	};

	const loadGoogleTagManager = (retry, maxRetries) => {
		const s = document.createElement("script");
		s.type = "text/javascript";
		s.async = true;
		s.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_TAG_MANAGER}`;
		s.onload = () => {
			window.dataLayer = window.dataLayer || [];
			function gtag() {
				dataLayer.push(arguments);
			}
			gtag("js", new Date());
			gtag("config", process.env.REACT_APP_GOOGLE_TAG_MANAGER);
		};
		s.onerror = () => {
			if (retry < maxRetries) {
				setTimeout(() => {
					loadGoogleTagManager(retry + 1, maxRetries);
				}, 1000);
			}
		};
		const x = document.querySelector("script");
		x.parentNode.insertBefore(s, x);
	};

	const loadReCAPTCHA = (retry, maxRetries) => {
		const s = document.createElement("script");
		s.type = "text/javascript";
		s.async = true;
		s.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA}`;
		s.onerror = () => {
			if (retry < maxRetries) {
				setTimeout(() => {
					loadReCAPTCHA(retry + 1, maxRetries);
				}, 1000);
			}
		};
		const x = document.querySelector("script");
		x.parentNode.insertBefore(s, x);
	};

	const loadSeonScript = (retry, maxRetries, leadId) => {
		const s = document.createElement("script");
		s.type = "text/javascript";
		s.async = true;
		s.src = "https://cdn.seondf.com/js/v5/agent.js";
		s.onload = () => {
			configureSeon(1, MAX_SCRIPTS_RETRIES, leadId);
		};
		s.onerror = () => {
			if (retry < maxRetries) {
				setTimeout(() => {
					loadSeonScript(retry + 1, maxRetries, leadId);
				}, 1000);
			}
		};
		const x = document.querySelector("script");
		x.parentNode.insertBefore(s, x);
	};

	const configureSeon = (retry, maxRetries, leadId) => {
		seon.config({
			host: location.hostname,
			session_id: leadId,
			audio_fingerprint: true,
			canvas_fingerprint: true,
			webgl_fingerprint: true,
			onSuccess: function () {
				seon.getBase64Session(function (data) {
					if (data) {
						dispatch(Actions.setSeonSession(data));
						return;
					}

					console.log("Failed to retrieve session data.");
				});
			},
			onError: function (message) {
				if (retry < maxRetries) {
					setTimeout(() => {
						configureSeon(retry + 1, maxRetries, leadId);
					}, 1000);
				}

				console.log("error", message);
			},
		});
	};

	const showPopup = popupsArray.length > 0;
	const renderPage = deviceState && initialRequestsDone;
	const Container = deviceState.isDesktop ? DesktopContainer : MobileContainer;

	return (
		<div className="App">
			<Container>
				{renderPage && generateRoutes()}
				{(showLoader || !initialRequestsDone) && !isAu10tixPath && <Loader />}
				{showPopup && <Popups />}
			</Container>
		</div>
	);
};

export default App;
