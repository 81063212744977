import Api from "api/requests";
import { generateDefaultFormData, isNumber, navigateToNextStep } from "app/functions";
import useApiError from "app/hooks/useApiError";
import useTranslate from "app/hooks/useTranslate";
import useValidate from "app/hooks/useValidate";
import Button from "components/forms/button";
import Hint from "components/forms/hint";
import RadioButton from "components/forms/radio-button";
import TextInput from "components/forms/textInput";
import HeaderTitle from "components/header_title";
import { BOOLEAN_VALUES, BUSINESS_INFO_TYPE, DELAY_DEBOUNCE } from "constants/input-fields";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Actions from "redux/actions";
import "./index.scss";

const BusinessOwner = ({ fields, location }) => {
	const MAX_SEON_RETRIES = 5;

	const defaultFormData = generateDefaultFormData(fields);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const translate = useTranslate();
	const signupForm = useSelector((store) => store.signupForm);
	const leadId = useSelector((store) => store.leadId);
	const seonSession = useSelector((store) => store.seonSession);

	const [firstTry, setFirstTry] = useState(true);
	const [formData, setFormData] = useState(defaultFormData);
	const validationObj = { defaultFormData, formData, setFormData, location };
	const validateForm = useValidate(validationObj);
	const handleServerError = useApiError();

	const businessTypeOptions = BUSINESS_INFO_TYPE.businessType.options;
	const businessNumber = signupForm[BUSINESS_INFO_TYPE.businessNumber.key];
	const customerBusinessType = signupForm[BUSINESS_INFO_TYPE.businessType.key];
	const isBusinessTypeDealer =
		customerBusinessType === businessTypeOptions.licensedDealer.value || customerBusinessType === businessTypeOptions.exemptDealer.value;

	const showOwnerNamesError =
		!firstTry && (formData[fields.ownerFirstName.name].isValid?.valid === false || formData[fields.ownerLastName.name].isValid?.valid === false);
	const ownerNamesErrorMessage = formData[fields.ownerFirstName.name].isValid?.errMsg || formData[fields.ownerLastName.name].isValid?.errMsg;

	useEffect(() => {
		const delayDebounce = setTimeout(() => {
			validateForm(false);
		}, DELAY_DEBOUNCE);

		return () => clearTimeout(delayDebounce);
	}, [signupForm]);

	useEffect(() => {
		if (isBusinessTypeDealer) {
			dispatch(Actions.updateSignupForm({ [fields.ownerIdNumber.name]: businessNumber }));
		}

		configureSeon(1, MAX_SEON_RETRIES, leadId);
	}, []);

	const configureSeon = (retry, maxRetries, leadId) => {
		if (!window.seon) {
			return;
		}

		seon.config({
			host: location.hostname,
			session_id: leadId,
			audio_fingerprint: true,
			canvas_fingerprint: true,
			webgl_fingerprint: true,
			onSuccess: function () {
				seon.getBase64Session(function (data) {
					if (data) {
						dispatch(Actions.setSeonSession(data));
						return;
					}
				});
			},
			onError: function (message) {
				if (retry < maxRetries) {
					setTimeout(() => {
						configureSeon(retry + 1, maxRetries, leadId);
					}, 1000);
				}
			},
		});
	};

	const handleInputChange = (e) => {
		let type = e?.target?.type;
		let name = e?.target?.name || e?.name;
		let val = e?.target?.value || e?.value;
		let key = formData[name]?.inputKey;

		if (type === "checkbox") {
			let isChecked = e.target.checked;
			key = e.target.name;

			if (!isChecked) {
				dispatch(Actions.removeFromSignupForm([key]));
				return;
			}

			val = BOOLEAN_VALUES[isChecked];
		} else if (type === "radio") {
			val = BOOLEAN_VALUES[val == BOOLEAN_VALUES.true];
		}

		if (formData[name]?.keyboardType === "tel" && !isNumber(val) && val?.length > 0) {
			return;
		}

		dispatch(Actions.updateSignupForm({ [key]: val }));
	};

	const handleNextButtonClick = (e) => {
		if (e) {
			e.preventDefault();
		}

		setFirstTry(false);

		let validationResult = validateForm(true);

		if (validationResult) {
			const onSuccess = (response) => {
				if (response.status === 1) {
					navigateToNextStep(validationResult, location, navigate);
				}
			};

			const onFailure = (response) => {
				if (response.status === 0) {
					handleServerError(response);
				}
			};

			const props = {
				onSuccess,
				onFailure,
				payload: {
					[fields.ownerFirstName.apiKey]: signupForm[fields.ownerFirstName.key],
					[fields.ownerLastName.apiKey]: signupForm[fields.ownerLastName.key],
					[fields.ownerIdNumber.key]: signupForm[fields.ownerIdNumber.key],
					[fields.ownerPhoneNumber.key]: signupForm[fields.ownerPhoneNumber.key],
					[fields.ownerEmailAddress.apiKey]: signupForm[fields.ownerEmailAddress.key],
					[fields.ownerRelationsWithPublicFigure.key]: signupForm[fields.ownerRelationsWithPublicFigure.key],
					token: seonSession,
				},
			};

			Api.validateOwner(props);
		}
	};

	return (
		<form className="business-info-owner-wrapper" onSubmit={handleNextButtonClick}>
			<div className="business-info-owner-data">
				<HeaderTitle>{translate(fields.headerTitle.label)}</HeaderTitle>

				<span className="business-info-owner-inputs-label">{translate(fields.ownerFirstName.inputLabel)}</span>
				<div className="business-info-owner-inputs-wrapper">
					<TextInput
						placeholder={translate(fields.ownerFirstName.placeholder)}
						name={fields.ownerFirstName.name}
						value={signupForm[fields.ownerFirstName.key] || ""}
						onChange={handleInputChange}
						className={"business-info-owner-inputs-width"}
						showError={false}
						autoHeight
					/>

					<TextInput
						placeholder={translate(fields.ownerLastName.placeholder)}
						name={fields.ownerLastName.name}
						value={signupForm[fields.ownerLastName.key] || ""}
						onChange={handleInputChange}
						className={"business-info-owner-inputs-width"}
						showError={false}
						autoHeight
					/>
				</div>

				<div className="business-info-owner-inputs-error-wrapper">{showOwnerNamesError ? ownerNamesErrorMessage : ""}</div>

				<TextInput
					keyboardType={fields.ownerEmailAddress.keyboardType}
					label={translate(fields.ownerEmailAddress.label)}
					placeholder={translate(fields.ownerEmailAddress.placeholder)}
					name={fields.ownerEmailAddress.name}
					value={signupForm[fields.ownerEmailAddress.key] || ""}
					onChange={handleInputChange}
					showError={!firstTry && formData[fields.ownerEmailAddress.name].isValid?.valid === false}
					errorMessage={formData[fields.ownerEmailAddress.name].isValid?.errMsg}
				/>

				<TextInput
					label={translate(fields.ownerIdNumber.label)}
					placeholder={translate(fields.ownerIdNumber.placeholder)}
					name={fields.ownerIdNumber.name}
					value={signupForm[fields.ownerIdNumber.key] || ""}
					onChange={handleInputChange}
					keyboardType={fields.ownerIdNumber.keyboardType}
					pattern="[0-9]*"
					showError={!firstTry && formData[fields.ownerIdNumber.name].isValid?.valid === false}
					errorMessage={formData[fields.ownerIdNumber.name].isValid?.errMsg}
					disabled={isBusinessTypeDealer}
				/>

				<TextInput
					label={translate(fields.ownerPhoneNumber.label)}
					subLabel={translate(fields.ownerPhoneNumber.subLabel)}
					value={signupForm[fields.ownerPhoneNumber.key] || ""}
					onChange={() => {}}
					className="owner-phone-number"
					disabled={true}
					isShort={true}
				/>

				<RadioButton
					type={fields.ownerRelationsWithPublicFigure.type}
					label={translate(fields.ownerRelationsWithPublicFigure.header)}
					options={fields.ownerRelationsWithPublicFigure.options}
					name={fields.ownerRelationsWithPublicFigure.name}
					showError={!firstTry && formData[fields.ownerRelationsWithPublicFigure.name].isValid?.valid === false}
					errorMessage={formData[fields.ownerRelationsWithPublicFigure.name].isValid?.errMsg}
					onChange={handleInputChange}
					value={signupForm[fields.ownerRelationsWithPublicFigure.key]}
				/>

				<BusinessOwnerHintBox fieldData={fields.ownerRelationsWithPublicFigure} />
			</div>

			<Button isNext={true} onClick={handleNextButtonClick} />
		</form>
	);
};

const BusinessOwnerHintBox = ({ fieldData }) => {
	const translate = useTranslate();

	// IF HINT DATA IS NOT AVAILABLE => DO NOT RENDER IT
	if (!translate(fieldData.hintHeader).trim() && !translate(fieldData.hintText).trim()) {
		return <></>;
	}

	return (
		<Hint>
			{translate(fieldData.hintHeader).trim() ? (
				<div className="business-info-owner-hint-header">{translate(fieldData.hintHeader)}</div>
			) : (
				<></>
			)}
			{translate(fieldData.hintText).trim() ? <div className="business-info-owner-hint-text">{translate(fieldData.hintText)}</div> : <></>}
		</Hint>
	);
};

export default BusinessOwner;
