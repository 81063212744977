import Api from "api/requests";
import { generateDefaultFormData, navigateToNextStep } from "app/functions";
import useApiError from "app/hooks/useApiError";
import useTranslate from "app/hooks/useTranslate";
import useValidate from "app/hooks/useValidate";
import IdImage from "assets/icons/id-icon.svg";
import Button from "components/forms/button";
import Hint from "components/forms/hint";
import HeaderTitle from "components/header_title";
import { BOOLEAN_VALUES, MANUAL_ID_UPLOAD_KEY } from "constants/input-fields";
import { useEffect, useRef, useState } from "react";
import HtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useOutletContext } from "react-router-dom";
import Actions from "redux/actions";
import UploadIdManually from "../UploadIdManually";
import "./index.scss";

const IdValidation = ({ fields, location }) => {
	const defaultFormData = generateDefaultFormData(fields);
	const [setContainerClassName] = useOutletContext();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const translate = useTranslate();
	const signupForm = useSelector((store) => store.signupForm);
	const loaderState = useSelector((store) => store.loaderState);
	const isDesktop = useSelector((store) => store.deviceState.isDesktop);

	const gdParams = useSelector((store) => store.gd.ErrorsLangParam.parameters);
	const maxNumberOfRetries = parseInt(gdParams.register_au10tix_max_tries ?? 3);
	const currentNumberOfRetries = useRef(1);

	const [formData, setFormData] = useState(defaultFormData);
	const [secureMeLink, setSecureMeLink] = useState("");
	const [isIframeShown, setIsIframeShown] = useState(false);
	const [iframeClass, setIframeClass] = useState(false);

	const validationObj = { defaultFormData, formData, setFormData, location };
	const validateForm = useValidate(validationObj);
	const handleServerError = useApiError();
	const isManualUpload = signupForm[MANUAL_ID_UPLOAD_KEY] ?? false;

	useEffect(() => {
		return () => dispatch(Actions.removeFromSignupForm([fields.idUpload.key]));
	}, []);

	useEffect(() => {
		if (loaderState) {
			return;
		}

		if (signupForm[fields.idUpload.key] === BOOLEAN_VALUES.true) {
			handleNextButtonClick();
		} else if (signupForm[fields.idUpload.key] === BOOLEAN_VALUES.false) {
			setIsIframeShown(false);
		}

		dispatch(Actions.removeFromSignupForm([fields.idUpload.key]));
	}, [signupForm[fields.idUpload.key], loaderState]);

	// * THIS FUNCTION IS CALLED WHEN AU10TIX REDIRECTS TO SUCCESS/FAILURE PAGE
	window.setSecureMeProcess = () => {
		handleSecureMeStatus();
	};

	const handleSecureMeStatusFailure = (response, customPopup = false) => {
		// * IF WE GOT HERE => THERE WAS AN ERROR WITH THE UPLOADED ID (NOT ISRAELI ID, NO MATCH AND SO ON...)
		// * HENCE, WE ARE DEFAULTING ALL THE STATES BACK TO GIVE THE CUSTOMER AN OPTION TO GET A NEW 'SECURE ME' LINK
		setSecureMeLink("");
		setIsIframeShown(false);
		dispatch(Actions.setWizardSteps({ backFlexStart: BOOLEAN_VALUES.false }));
		setContainerClassName("");
		handleServerError(customPopup || response);
	};

	const handleSecureMeStatus = () => {
		const continueToNextStep = () => {
			dispatch(Actions.setWizardSteps({ isBackDisabled: BOOLEAN_VALUES.true, backFlexStart: BOOLEAN_VALUES.false }));
			setContainerClassName("");
			const updateFormData = { [fields.idUpload.key]: BOOLEAN_VALUES.true, [fields.idUpload.validationKey]: BOOLEAN_VALUES.true };
			dispatch(Actions.updateSignupForm(updateFormData));
		};

		const onSuccess = (response) => {
			if (response.status === 1) {
				continueToNextStep();
			}
		};

		const onFailure = (response) => {
			if (response.status === 0) {
				if (currentNumberOfRetries.current >= maxNumberOfRetries) {
					handleFailedAu10tix();
					return;
				}

				handleFailedAu10tixCounter(response, false);
			}
		};

		const onCatch = (error) => {
			// the goal here is to count the number of retry attempts to give the user X amount of retries before continuing to the next step
			if (currentNumberOfRetries.current >= maxNumberOfRetries) {
				handleFailedAu10tix();
				return;
			}

			handleFailedAu10tixCounter(error);
		};

		const props = { onSuccess, onFailure, onCatch };
		Api.checkSecureMeStatus(props);
	};

	const handleFailedAu10tix = () => {
		const onSuccess = (response) => {
			if (response.status === 1) {
				dispatch(Actions.updateSignupForm({ [MANUAL_ID_UPLOAD_KEY]: BOOLEAN_VALUES.true }));
			}
		};

		const onFailure = (response) => {
			if (response.status === 0) {
				handleSecureMeStatusFailure(response);
			}
		};

		const props = { payload: { status_authentication: 1 }, onSuccess, onFailure };
		Api.updateFailedAu10tix(props);
	};

	const handleFailedAu10tixCounter = (error, isCustomPopup = true) => {
		currentNumberOfRetries.current += 1;
		const failurePopupPayload = { err: { message: "au10tix_popup_error_message_content" }, btnText: "au10tix_popup_error_message_button" };
		handleSecureMeStatusFailure(error, isCustomPopup ? failurePopupPayload : false);
	};

	const handleSecureMeIframe = () => {
		const onSuccess = (response) => {
			if (response.status === 1) {
				const url = response.data.url;
				setSecureMeLink(url);
				setIsIframeShown(true);
				dispatch(Actions.setWizardSteps({ backFlexStart: BOOLEAN_VALUES.true }));
				setContainerClassName(isDesktop ? "" : "thin-padding");
			}
		};

		const onFailure = (response) => {
			if (response.status === 0) {
				handleServerError(response);
			}
		};

		const props = {
			onSuccess,
			onFailure,
		};

		Api.getSecureMeLink(props);
	};

	const handleNextButtonClick = (e) => {
		if (e) {
			e.preventDefault();
		}

		let validationResult = validateForm(true);

		if (validationResult) {
			navigateToNextStep(validationResult, location, navigate);
		}
	};

	const createListItems = () => {
		const splittedList = translate("upload_id_info_content").split("$");
		const resultArr = [];

		for (let [itemIdx, itemValue] of Object.entries(splittedList)) {
			if (!itemValue) {
				continue;
			}

			const listItem = <li key={`id_instructions_${itemIdx}`}>{HtmlParser(itemValue)}</li>;
			resultArr.push(listItem);
		}

		return resultArr;
	};

	const instructionsListItems = createListItems();
	const instructionsListHeader = translate("upload_id_info_content_header");

	return (
		<form className="id-validation-wrapper" onSubmit={handleNextButtonClick}>
			<div className="id-validation-data">
				{isManualUpload ? (
					<UploadIdManually fields={fields} location={location} />
				) : (
					<>
						{isIframeShown ? (
							<iframe
								className={`id-validation-secure-me-iframe ${iframeClass}`}
								src={secureMeLink}
								onLoad={() => {
									setIframeClass("active");
								}}
								allow="camera;microphone"
								title={translate("upload_id_iframe_title")}
							></iframe>
						) : (
							<>
								<HeaderTitle>{translate("upload_id_header")}</HeaderTitle>

								<div className="id-validation-form-info">
									<div className="id-validation-image-placeholder">
										<img src={IdImage} alt={translate("upload_id_image_alt")} />
									</div>

									{instructionsListHeader?.trim() ? (
										<div className="id-validation-instructions-header">{HtmlParser(instructionsListHeader)}</div>
									) : (
										<></>
									)}
									{instructionsListItems?.length > 0 ? (
										<ul className="id-validation-instructions-list">{instructionsListItems}</ul>
									) : (
										<></>
									)}

									<Hint>
										<div className="id-validation-hint-header">{translate("upload_id_hint_header")}</div>
										<div className="id-validation-hint-content">{translate("upload_id_hint_content")}</div>
									</Hint>
								</div>

								<Button isNext onClick={handleSecureMeIframe} />
							</>
						)}
					</>
				)}
			</div>
		</form>
	);
};

export default IdValidation;
