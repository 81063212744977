import { interpolate } from "app/functions";
import { useCallback } from "react";
import { useSelector } from "react-redux";

const useTranslate = () => {
	const translations = useSelector((store) => store.translations);

	const translate = useCallback(
		(key, replacements) => {
			if (!translations) {
				return "";
			}

			let translation = translations[key] ?? key;

			if (replacements) {
				translation = interpolate(translation, replacements);
			}

			return translation;
		},
		[translations],
	);

	return translate;
};

export default useTranslate;
