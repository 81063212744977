import { generateUniqueId } from "app/functions";
import React, { Component } from "react";
import dropdownOpenArrow from "../../../../assets/autocomplete/dropdown-open.svg";
import dropdownCloseArrow from "../../../../assets/autocomplete/dropdown-close.svg";
import "./index.scss";
import { connect } from "react-redux";

class AutoComplete extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: this.props.isOpen ? this.props.isOpen : false,
			highlighted_item: -1,
		};

		this.handleClick = this.handleClick.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleDropdownArrow = this.handleDropdownArrow.bind(this);
		this.handleKeyUp = this.handleKeyUp.bind(this);
		this.handleOptionClick = this.handleOptionClick.bind(this);
		this.selectionArrowClassName = "autocomplete_selection_arrow";
	}

	componentDidMount() {
		if (this.props.isOpen) {
			this.nameInput.focus();
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.isOpen !== prevProps.isOpen) {
			this.setState({ open: this.props.isOpen });
		}

		// * IF SEARCH QUERY HAS UPDATED => RESET THE HIGHLIGHTED ITEM BACK TO ORIGINAL STATE
		const oldQuery = (prevProps.query ? prevProps.query : prevProps.form?.[prevProps.name]) || "";
		if (oldQuery !== this.getQuery()) {
			this.setState({ highlighted_item: -1 });
		}
	}

	handleClick(event) {
		//prevent form submission
		event.preventDefault();

		//fix for enter keyup event triggering click events - 0 = Enter key, 1 = click event
		// if (!(event.detail === 0)) {
		// let newState = !this.state.open;
		// this.setState({ open: newState });
		// }
	}

	handleClickOutside() {
		setTimeout(() => {
			this.setState({ open: false });
		}, 100);
	}

	handleDropdownArrow(e) {
		e.preventDefault();

		if (this.props.disabled) {
			return;
		}

		this.setState({ open: !this.state.open });
	}

	getQuery() {
		return (this.props.query ? this.props.query : this.props.form?.[this.props.name]) || "";
	}

	filterOptions = (options) => {
		if (this.props.noFilter) {
			return options;
		}

		const queryString = this.getQuery();
		let filteredOptions = options.filter((option) => option[this.props.fieldKey].indexOf(queryString) !== -1);

		return filteredOptions;
	};

	handleKeyUp(event) {
		this.filterOptions(this.props.options);

		let highlighted_item = this.state.highlighted_item;
		let highlighted_option = document.getElementsByClassName("highlight");
		let selected_option_id = -1;

		if (highlighted_option.length > 0) {
			selected_option_id = highlighted_option[0].getAttribute("id");
		}

		switch (event.key) {
			case "ArrowDown":
				highlighted_item + 1 > this.props.options.length ? (highlighted_item = 0) : highlighted_item++;
				this.setState({ highlighted_item: highlighted_item });
				break;

			case "ArrowUp":
				highlighted_item - 1 < 0 ? (highlighted_item = this.props.options.length) : highlighted_item--;
				this.setState({ highlighted_item: highlighted_item });
				break;

			case "Enter":
				if (this.state.open === false) {
					if (event.target.className !== this.selectionArrowClassName) {
						this.setState({ open: true });
					}
				} else {
					let selected_item = this.props.options.find((option) => option.id === parseInt(selected_option_id));
					const isLastItem = highlighted_option[0].getAttribute("last-item");

					if (isLastItem) {
						highlighted_option[0]?.click();
					}

					if (selected_item) {
						const selectedData = {
							name: this.props[this.props.fieldKey],
							data: selected_item,
							target: {
								type: "autocomplete",
							},
						};
						this.props.onSelect(selectedData);
					}

					if (event.target.className !== this.selectionArrowClassName) {
						this.setState({ open: false });
					}
				}
				break;

			case "Escape":
				this.setState({ highlighted_item: -1, open: false });
				break;
			default:
				break;
		}
	}

	handleOptionClick(event) {
		event.stopPropagation();
		event.preventDefault();

		this.setState({ open: false });
		let selected_item = this.props.options.find((item) => {
			return item.id === parseInt(event.target.id);
		});

		if (selected_item) {
			const selectedData = {
				name: this.props[this.props.fieldKey] ?? this.props.name,
				data: selected_item,
				target: {
					type: "autocomplete",
				},
			};

			this.props.onSelect(selectedData);
		}
	}

	// getPartSpan(text, match, space = false, id = null) {
	//     let key = Math.floor(Math.random() * 100000);
	//     let component = null;

	//     if (space) {
	//         component = (
	//             <span className="part" key={key} id={id}>
	//                 &nbsp;
	//             </span>
	//         );
	//     } else {
	//         component = (
	//             <span className={match ? "match" : "part"} key={key} id={id}>
	//                 {text}
	//             </span>
	//         );
	//     }
	//     return component;
	// }

	// replaceSpaces(parts, text, match = false, id = null) {
	//     let space_index = text.indexOf(" ");
	//     if (space_index !== -1) {
	//         if (space_index === 0) {
	//             text = text.replace(/\s/g, "");
	//             parts.push(this.getPartSpan("", false, true, id));
	//             parts.push(this.getPartSpan(text, match, false, id));
	//         } else if (space_index === text.length - 1) {
	//             text = text.replace(/\s/g, "");
	//             parts.push(this.getPartSpan(text, match, false, id));
	//             parts.push(this.getPartSpan("", false, true, id));
	//         } else {
	//             parts.push(this.getPartSpan(text, match, false, id));
	//         }
	//     } else {
	//         parts.push(this.getPartSpan(text, match, false, id));
	//     }
	// }

	render() {
		const {
			options,
			selected_id = -1,
			label = "",
			placeholder = "",
			query = "",
			disabled = false,
			className = "",
			tabIndex,
			onChange,
			name,
		} = this.props;

		const handleInputChange = (e) => {
			if (!this.state.open) {
				this.setState({ open: true });
			}

			onChange(e);
		};

		const errorMessageId = `${generateUniqueId(12)}_err`;
		const alwaysShowResults = Boolean(this.props.alwaysShowResults);
		let activeClass = this.state.open && (options.length > 0 || alwaysShowResults) ? "active" : "";
		const queryString = this.getQuery();
		let filteredOptions = queryString === "" ? options : this.props.noFilter ? options : this.filterOptions(options);

		const occupationAreaValidation = this.props.validation?.[this.props.showError]?.isValid;

		const dropdownArrowText = () => {
			const isOpen = this.state.open;
			const translations = this.props.translations;

			if (isOpen) {
				return translations["dropdown_close_arrow_text"];
			}

			return translations["dropdown_open_arrow_text"];
		};

		return (
			<div className="autocomplete_container">
				{label?.trim() !== "" && <label className="autocomplete_label"> {label}</label>}
				<div
					className={
						"auto_wrapper " +
						className +
						" " +
						activeClass +
						(disabled ? " disabled" : "") +
						(filteredOptions.length === 0 ? " no-results" : "")
					}
					tabIndex={tabIndex}
					onBlur={this.handleClickOutside}
					onKeyUp={this.handleKeyUp}
				>
					<input
						className="auto_input"
						type="text"
						placeholder={placeholder}
						onClick={(e) => this.handleClick(e)}
						onChange={handleInputChange}
						value={(query ? query : this.props.form?.[name]) || ""}
						name={name}
						disabled={disabled}
						autoComplete={"off"}
						ref={(input) => {
							this.nameInput = input;
						}}
						data-type={"autocomplete"}
						{...(this.props.autocomplete[name] ? { "data-selected": this.props.autocomplete[name] } : {})}
						{...(this.props.showError ? { "aria-describedby": errorMessageId } : {})}
					/>

					<ul className="auto_menu">
						{filteredOptions.map((item, index) => {
							let activeOption = "";
							let highlightedOption = "";

							if (item.id === selected_id) {
								activeOption = "active";
							}

							if (index === this.state.highlighted_item) {
								highlightedOption = " highlight";
							}

							let optionText = (item.text ? item.text : item[this.props.fieldKey]) || "";
							let searchQuery = this.getQuery();

							const getParts = () => {
								const regex = new RegExp(`(${searchQuery})`, "gi");
								const parts = optionText.split(regex);

								return (
									<span id={item.id}>
										{parts.map((part, i) => (
											<span
												key={i}
												className={part.toLowerCase() === searchQuery.toLowerCase() ? "match" : "part"}
												id={item.id}
											>
												{part}
											</span>
										))}
									</span>
								);
							};

							return (
								<li
									className={"auto_option " + activeOption + highlightedOption}
									key={item?.id ? item.id : index}
									id={item.id}
									onMouseDown={this.handleOptionClick}
									onClick={this.handleOptionClick}
								>
									{getParts()}
								</li>
							);
						})}

						{this.props.lastItemElement && (
							<li
								className={`auto_option ${filteredOptions.length === this.state.highlighted_item ? "highlight" : ""}`}
								id={filteredOptions.length}
								last-item="1"
								onClick={this.props.lastItemCallback}
							>
								{this.props.lastItemElement}
							</li>
						)}
					</ul>

					{!this.props.noDropdown && (
						<button
							className={this.selectionArrowClassName}
							onClick={this.handleDropdownArrow}
							aria-label={dropdownArrowText()}
							disabled={disabled}
							type="button"
						>
							<img src={this.state.open ? dropdownOpenArrow : dropdownCloseArrow} alt={dropdownArrowText()} />
						</button>
					)}
				</div>

				{this.props.isOccupation ? (
					occupationAreaValidation?.valid === false ? (
						<div className="autocomplete_error_message" id={errorMessageId}>
							{occupationAreaValidation.errMsg}
						</div>
					) : (
						<div className="autocomplete_error_message_placeholder"></div>
					)
				) : (
					this.props.showError && (
						<div className="autocomplete_error_message" id={errorMessageId}>
							{this.props.errorMessage}
						</div>
					)
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		translations: state.translations,
		form: state.occupationAreaData.form,
		autocomplete: state.occupationAreaData.autocomplete,
		validation: state.occupationAreaData.validation,
	};
};

export default connect(mapStateToProps)(AutoComplete);
