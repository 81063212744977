import Api from "api/requests";
import { generateDefaultFormData, isNumber } from "app/functions";
import useApiError from "app/hooks/useApiError";
import useTranslate from "app/hooks/useTranslate";
import useValidate from "app/hooks/useValidate";
import Button from "components/forms/button";
import Checkbox from "components/forms/checkbox";
import HeaderTitle from "components/header_title";
import { BOOLEAN_VALUES, BUSINESS_INFO_OWNER, BUSINESS_INFO_TYPE, CONTACT_INFO_GENERAL, DELAY_DEBOUNCE } from "constants/input-fields";
import { mainRootRoutes } from "constants/main-routes";
import routesMap from "constants/route-map";
import { useEffect, useState } from "react";
import HtmlParser, { convertNodeToElement } from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Actions from "redux/actions";
import "./index.scss";

const GeneralAgreementTerms = ({ fields, location }) => {
	const defaultFormData = generateDefaultFormData(fields);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const translate = useTranslate();
	const signupForm = useSelector((store) => store.signupForm);
	const seonSession = useSelector((store) => store.seonSession);

	const [firstTry, setFirstTry] = useState(true);
	const [formData, setFormData] = useState(defaultFormData);
	const validationObj = { defaultFormData, formData, setFormData, location };
	const validateForm = useValidate(validationObj);
	const handleServerError = useApiError();

	const isOwnerType = BUSINESS_INFO_OWNER.isFormRequired();
	const isContactType = CONTACT_INFO_GENERAL.isFormRequired();
	const isNextButtonDisabled =
		signupForm[fields.agreeCheckbox.name] !== BOOLEAN_VALUES.true || signupForm[fields.beneficiariesCheckbox.name] !== BOOLEAN_VALUES.true;

	useEffect(() => {
		dispatch(Actions.setWizardSteps({ isBackDisabled: BOOLEAN_VALUES.true }));
	}, []);

	useEffect(() => {
		const delayDebounce = setTimeout(() => {
			validateForm(false);
		}, DELAY_DEBOUNCE);

		return () => clearTimeout(delayDebounce);
	}, [signupForm]);

	const handleInputChange = (e) => {
		let type = e?.target?.type;
		let name = e?.target?.name || e?.name;
		let val = e?.target?.value || e?.value;
		let key = formData[name]?.inputKey;

		if (type === "checkbox") {
			let isChecked = e.target.checked;
			key = e.target.name;

			if (!isChecked) {
				dispatch(Actions.removeFromSignupForm([key]));
				return;
			}

			val = BOOLEAN_VALUES[isChecked];
		} else if (type === "radio") {
			val = BOOLEAN_VALUES[val == BOOLEAN_VALUES.true];
		}

		if (formData[name]?.keyboardType === "tel" && !isNumber(val) && val?.length > 0) {
			return;
		}

		dispatch(Actions.updateSignupForm({ [key]: val }));
	};

	const handleNextButtonClick = (e) => {
		if (e) {
			e.preventDefault();
		}

		// * DISABLE BUTTON CLICK IF CHECKBOXES ARE NOT SELECTED
		if (isNextButtonDisabled) {
			return;
		}

		setFirstTry(false);

		let validationResult = validateForm(true);

		if (validationResult) {
			const onSuccess = (response) => {
				if (response.status === 1) {
					const registrationSuccessPath = `/${mainRootRoutes.agreementTerm}/${routesMap.agreementTerm.subRoutes.congratulations.path}`;
					navigate(registrationSuccessPath);
				}
			};

			const onFailure = (response) => {
				if (response.status === 0) {
					handleServerError(response);
				}
			};

			const props = {
				onSuccess,
				onFailure,
				payload: {
					token: seonSession,
					[fields.beneficiariesCheckbox.key]: signupForm[fields.beneficiariesCheckbox.key],
				},
			};

			Api.approveTerms(props);
		}
	};

	const agreementTermsText = () => {
		const baseTerms = translate(fields.terms.base);
		const marketerTerms = signupForm[fields.terms.extended] ?? "";

		return baseTerms + marketerTerms;
	};

	const checkboxLabel = () => {
		let baseTemplate, businessNumber, firstName, lastName;

		if (isOwnerType) {
			baseTemplate = translate(fields.agreeCheckbox.labels.owner);

			businessNumber = signupForm[BUSINESS_INFO_OWNER.ownerIdNumber.key];
			firstName = signupForm[BUSINESS_INFO_OWNER.ownerFirstName.key];
			lastName = signupForm[BUSINESS_INFO_OWNER.ownerLastName.key];
		} else if (isContactType) {
			baseTemplate = translate(fields.agreeCheckbox.labels.contact);

			businessNumber = signupForm[BUSINESS_INFO_TYPE.businessNumber.key];
			firstName = signupForm[CONTACT_INFO_GENERAL.contactFirstName.key];
			lastName = signupForm[CONTACT_INFO_GENERAL.contactLastName.key];
		}

		const fullLabel = baseTemplate?.replace("{{name}}", `${firstName} ${lastName}`)?.replace("{{business_number}}", businessNumber);
		return fullLabel;
	};

	const transformContent = (node, index) => {
        // Currently we only handle anchor tags, but more can be added if needed
		// Check if the node is an anchor tag
		if (node.type === "tag" && node.name === "a") {
			// Create a new props object with the existing properties
			const newProps = { ...node.attribs, target: "_blank", rel: "noopener noreferrer" };

			// Return the modified element with new props
			return (
				<a key={index} {...newProps}>
					{node.children.map((child, i) => convertNodeToElement(child, i, transformContent))}
				</a>
			);
		}
	};

	return (
		<form className="agreement-terms-general-wrapper" onSubmit={handleNextButtonClick}>
			<div className="agreement-terms-general-data">
				<HeaderTitle>{translate(fields.headerTitle.label)}</HeaderTitle>

				<div className="agreement-terms-general-text">{HtmlParser(agreementTermsText(), { transform: transformContent })}</div>

				<div className="checkboxes-wrapper">
					<div className="agreement-terms-general-checkbox-wrapper">
						<Checkbox
							key={fields.agreeCheckbox.name}
							type={fields.agreeCheckbox.type}
							id={fields.agreeCheckbox.id}
							name={fields.agreeCheckbox.name}
							label={checkboxLabel()}
							onChange={handleInputChange}
							showError={!firstTry && formData[fields.agreeCheckbox.name].isValid?.valid === false}
							errorMessage={formData[fields.agreeCheckbox.name].isValid?.errMsg}
							checked={signupForm[fields.agreeCheckbox.name] === BOOLEAN_VALUES.true}
							isShort
						/>
					</div>

					<Checkbox
						type={fields.beneficiariesCheckbox.type}
						id={fields.beneficiariesCheckbox.id}
						name={fields.beneficiariesCheckbox.name}
						label={translate(fields.beneficiariesCheckbox.label)}
						onChange={handleInputChange}
						showError={!firstTry && formData[fields.beneficiariesCheckbox.name].isValid?.valid === false}
						errorMessage={formData[fields.beneficiariesCheckbox.name].isValid?.errMsg}
						checked={signupForm[fields.beneficiariesCheckbox.name] === BOOLEAN_VALUES.true}
						isShort
					/>
				</div>
			</div>

			<Button isNext={true} onClick={handleNextButtonClick} disabled={isNextButtonDisabled} />
		</form>
	);
};

export default GeneralAgreementTerms;
